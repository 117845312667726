//import _block from '../core/_schema/_block'
//import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/menus',
  resource: {
    definition: 'Menu-menu:read',
    properties: {

      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          description: {
            type: 'textarea',
          },
//          text: {
//            type: 'textarea',
//          },
//          slug: {},
        },
      },
    },
  },

}

export default schema
