import { authHeader } from 'core/_helpers/authHeader'

const login = (email, password, _remember_me) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, _remember_me }),
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/authentication_token`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      const { token } = response

      return token
    })
}

const validate = token => {
  const requestOptions = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/validate_token`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(() => token)
}

const refresh = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/refresh_token`

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      const { token } = response

      return token
    })
}

const handleResponse = response =>
  response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })

export const authService = {
  login,
  validate,
  refresh,
}
