export const textWithSections = (
  photoEndpoint,
  sectionEndpoint,
  sectionThumbEndpoint
) => ({
  name: 'textWithSections',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {},
        text: {
          type: 'tinymce',
        },
      },
    },
    sections: {
      type: 'collection',
      endpoint: sectionEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            textBefore: {
              type: 'textarea',
            },
            textAfter: {
              type: 'textarea',
            },
            anchorTitle: {},
          },
        },
        type: {
          type: 'media[type]',
        },
        photo: {
          type: 'media[image]',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoEndpoint,
          thumbs: sectionThumbEndpoint && {
            endpoint: sectionThumbEndpoint,
          },
          validate: ['maxSize'],
        },
        youtube: {
          type: 'media[youtube]',
          description: 'T_GENERAL_YOUTUBE_CODE',
        },
      },
    },
  },
})
