//import _block from '../core/_schema/_block'
import _block from './_antiqueBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import antiqueSchema from './antique'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/antiques',
  preview: '/preview/antiques/:id',
  resource: {
    definition: 'Antique-antique:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          text: {
            type: 'textarea',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
        },
      },

      youtube: {
       type: 'youtube',
        fullWidth: false,
      },
//    photo jest ale jest wyłączone
//      photo: {
//        type: 'image',
//        description: 'T_GENERAL_PHOTO',
//        endpoint: photoSchema.endpoint.single,
//        //endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/antique_thumbs',
//        },
//        validate: ['maxSize'],
//      },

      stat:{
        type: 'boolean',
      },

      relatedAntiques: {
        description: 'T_GENERAL_RELATED_ANTIQUES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/antiques?order[createdAt]=desc&pagination=false`,
      },
      relatedArticles: {
        description: 'T_GENERAL_RELATED_ARTICLES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/articles?order[createdAt]=desc&pagination=false`,
      },
      relatedEvents: {
        description: 'T_GENERAL_RELATED_EVENTS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/events?order[ord]=desc&pagination=false`,
      },
      relatedExhibitions: {
        description: 'T_GENERAL_RELATED_EXHIBITIONS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/exhibitions?order[ord]=desc&pagination=false`,
      },
      relatedKnowledges: {
        description: 'T_GENERAL_RELATED_KNOWLEDGES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledges?order[ord]=desc&pagination=false`,
      },

    },
  },
  subresources: {
    block: {
      endpoint: '/antique_blocks',
      definition: 'AntiqueBlock-antiqueBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/antique_block_sections',
        '/antique_block_thumbs',
        '/antique_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
