
const schema = {
  endpoint: '/config_volunteerings',
  resource: {
    definition: 'Config-config:volunteerings_read',
    properties: {

//      translations: {
//        type: 'translation',
//        properties: {
//
//        },
//      },
      volunteeringFormUrl: {},
      titleVolunteeringContact1: {
        type: 'title',
        description: 'Osoba kontaktowa 1',
      },
      volunteeringContact1FirstLastName: {},
      volunteeringContact1Phone: {},
      volunteeringContact1Email: {},
      titleVolunteeringContact2: {
        type: 'title',
        description: 'Osoba kontaktowa 2',
      },
      volunteeringContact2FirstLastName: {},
      volunteeringContact2Phone: {},
      volunteeringContact2Email: {},
      titleVolunteeringContact3: {
        type: 'title',
        description: 'Osoba kontaktowa 3',
      },
      volunteeringContact3FirstLastName: {},
      volunteeringContact3Phone: {},
      volunteeringContact3Email: {},
    },
  },
}

export default schema
