export const textWithFile = (fileEndpoint) => ({
  name: 'textWithFile',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_FILE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        text: {
          type: 'tinymce',
        },
        buttonTitle: {
            label: 'Napis na przycisku',
            fullWidth: false
        },
        anchorTitle: {},
      },
    },
    file: {
      type: 'file',
      fullWidth: false,
      label: 'T_GENERAL_FILE',
      endpoint: fileEndpoint,
    },
  },
})
