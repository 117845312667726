import React, { useCallback, useMemo }  from 'react'
import { Form } from 'core/pages'
import schema from '_schema/event'
import routes from './routes'

import { BlockCollection } from 'core/components/block'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'

import packageFileSchema from 'core/_schema/packageFile'
import packageGallerySchema from 'core/_schema/packageGallery'
import packageFaqSchema from 'core/_schema/packageFaq'
import packageFormSchema from 'core/_schema/packageForm'


import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from 'core/components/Title'
import { translate } from 'core/_helpers/translate'
import { CustomButton } from 'core/components/CustomButton'
import { Visibility } from '@material-ui/icons'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
  preview: {
    marginBottom: 10,
  },
})


export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const packageResourcesMapping = useMemo(
    () => ({
      packageFile: packageFileSchema.endpoint,
      packageGallery: packageGallerySchema.endpoint,
      packageFaq: packageFaqSchema.endpoint,
      packageForm: packageFormSchema.endpoint,
    }),
    []
  )

  const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)

  const blockTypes = useMemo(
    () =>
      addBlockPropertyResources(
        schema.subresources.block.types,
        packageResources
      ),
    [packageResources]
  )
  
  const classes = useStyles()
    
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      width="100%"
      header={
        <Container maxWidth="xl" className={classes.header}>
          <Title>{translate('T_GENERAL_EDIT')}</Title>
          <CustomButton
            title={translate('T_GENERAL_PREVIEW')}
            path={routes().preview.path.replace(':id', match.params.id)}
            color="default"
            startIcon={<Visibility />}
            className={classes.preview}
          />
        </Container>
      }
    >
    
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={blockTypes}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />    
            
    </Form>
  )
}
