//import _block from './_block'
import _block from './_pageBlock'
import photoSchema from './photo'
import fileSchema from './file'

const schema = {
  endpoint: '/pages',
  preview: '/preview/pages/:id',
  resource: {
    definition: 'Page-page:read',
    properties: {
      translations: {
        type: 'translation',
        contextualHint: 'how_to_edit',
        properties: {
          title: {
              type: 'textarea',
          },
          shortDescription: {},
          text: {
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          redirectToUrl: {
            type: 'textarea',
            fullWidth: true,
          },
        },
      },
      redirectToPage: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithParent`,
        endpoint: `/pages?order[ord]=desc&pagination=false`,
      },
      redirectAnchor: {},
      isNotSearchable: {
        type: 'boolean',
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PAGE_IMAGE',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/page_thumbs',
        },
        validate: ['maxSize'],
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/page_blocks',
      definition: 'PageBlock-pageBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/page_block_sections',
        '/page_block_thumbs',
        '/page_block_section_thumbs'
      ).types,
    },
  },
}

export default schema
