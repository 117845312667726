const schema = {
  endpoint: '/knowledge_epochs',
  resource: {
    definition: 'KnowledgeEpoch-knowledgeEpoch:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
        },
      },
    },
  },
}

export default schema
