
const schema = {
  endpoint: '/configs',
  resource: {
    definition: 'Config-config:read',
    properties: {

//      translations: {
//        type: 'translation',
//        properties: {
//
//        },
//      },
      guideBookingFormUrl: {},

    },
  },
}

export default schema
