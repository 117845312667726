import { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

const PER_PAGE = 50

const fetchResource = (endpoint, setTemporalState, setState, field, page) => {
  const controller = new AbortController()
  const { signal } = controller

  fetchDataHandleAuthError(
    `${endpoint}${
      endpoint.includes('?') ? '&' : '?'
    }page=${page}&perPage=${PER_PAGE}`,
    'GET',
    { signal },
    response => {
      setTemporalState(tempState => {
        if (
          tempState[field].length + response['hydra:member'].length >=
          response['hydra:totalItems']
        ) {
          setState(state => ({
            ...state,
            [field]: [...tempState[field], ...response['hydra:member']],
          }))
        }

        return {
          ...tempState,
          [field]: [...tempState[field], ...response['hydra:member']],
        }
      })

      if (page * PER_PAGE < response['hydra:totalItems']) {
        fetchResource(endpoint, setTemporalState, setState, field, page + 1)
      }
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
    }
  )

  return () => controller.abort()
}

export const usePropertyResourcesFetch = properties => {
  const [, setTemporalState] = useState(
    Object.assign({}, ...Object.keys(properties).map(name => ({ [name]: [] })))
  )

  const [state, setState] = useState(
    Object.assign({}, ...Object.keys(properties).map(name => ({ [name]: [] })))
  )

  useEffect(() => {
    const aborters = Object.keys(properties).map(name => {
      return fetchResource(
        properties[name],
        setTemporalState,
        setState,
        name,
        1
      )
    })

    return () => aborters.map(aborter => aborter())
  }, [properties, setState, setTemporalState])

  return [state, setState]
}
