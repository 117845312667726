import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.elements.menu.text,
    '& *': {
      color: theme.elements.menu.text,
    },
  },
  active: {
    color: theme.elements.menu.active,
    '& *': {
      color: theme.elements.menu.active,
    },
  },
  listItem: {
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      backgroundColor: theme.elements.menu.hover,
    },
  },
  listItemIcon: {
    minWidth: 44,
  },
  icon: {
    fontSize: 22,
  },
}))

export const MenuItem = ({ item, exact = false, classes = {} }) => {
  const defaultClasses = useStyles()

  const IconComponent = item.icon
  return (
    <NavLink
      to={item.path}
      className={clsx(defaultClasses.link, classes.link)}
      activeClassName={clsx(defaultClasses.active, classes.active)}
      exact={exact || (item.path.length > 1 ? false : !!item.exact)}
    >
      <ListItem
        button
        className={clsx(defaultClasses.listItem, classes.listItem)}
      >
        <ListItemIcon
          className={clsx(defaultClasses.listItemIcon, classes.listItemIcon)}
        >
          <IconComponent
            color="inherit"
            className={clsx(defaultClasses.icon, classes.icon)}
          />
        </ListItemIcon>
        <div>{translate(item.title)}</div>
      </ListItem>
    </NavLink>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  exact: PropTypes.bool,
  classes: PropTypes.shape({
    link: PropTypes.string,
    active: PropTypes.string,
    listItem: PropTypes.string,
    listItemIcon: PropTypes.string,
    icon: PropTypes.string,
  }),
}
