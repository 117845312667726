import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'

import { translate } from 'core/_helpers/translate'

const FORMAT = 'YYYY-MM-DD HH:mm'

export const VariousPropertiesCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
//    if (isRowLinkable) {
//      editPath &&
//        history.push(
//          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
//        )
//    }
  }

  const value = prop(resource, accessor)

  return (
    <div onClick={onClick}>
        {Object.keys(value).map((fieldName, index) => (
            (value[fieldName].length > 0 && 
                <div key={index}>
                    <strong>{translate(fieldName)}</strong>: {value[fieldName]}
                </div>
            )
        ))}
    </div>
  )
}

//DateTimeCell.propTypes = {
//  resource: PropTypes.shape({
//    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
//      PropTypes.number,
//      PropTypes.string,
//    ]).isRequired,
//    '@id': PropTypes.string.isRequired,
//  }).isRequired,
//  accessor: PropTypes.oneOfType([
//    PropTypes.string,
//    PropTypes.arrayOf(PropTypes.string),
//  ]).isRequired,
//  isRowLinkable: PropTypes.bool,
//  editPath: PropTypes.string,
//}
