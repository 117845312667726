const schema = {
  endpoint: '/education_targets',
  resource: {
    definition: 'EducationTarget-educationTarget:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          shortDescription: {
            validate: [],
          },
          text: {
            type: 'textarea',
          },
          slug: {},
          redirectToUrl: {
            type: 'textarea',
            fullWidth: true,
          },
        },
      },
      redirectToPage: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithParent`,
        endpoint: `/pages?order[ord]=desc&pagination=false`,
        fullWidth: false,
      },
      redirectAnchor: {
          fullWidth: false,
      },
      eventAgeGroups: {
        description: 'T_GENERAL_RELATED_EVENT_AGE_GROUPS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articleSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/event_age_groups?order[ord]=desc&pagination=false`,
      },
    },
  },
}

export default schema
