//import _block from '../core/_schema/_block'
//import _block from './_sponsorPersonBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import calendarSchema from './calendar'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/sponsor_persons',
  resource: {
    definition: 'SponsorPerson-sponsorPerson:read',
    properties: {

      firstLastName: {},
  
// zdjęcie na osobach jest uruchomione ale nie było w projekcie wiec zaremowane i czeka jak zaczną potrzebować
// 
//      photo: {
//        type: 'image',
//        description: 'T_GENERAL_PHOTO',
//        endpoint: photoSchema.endpoint.single,
//        //endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/sponsor_person_thumbs',
//        },
//        validate: ['maxSize'],
//      },
    },
  },
  subresources: {
//    block: {
//      endpoint: '/calendar_blocks',
//      definition: 'SponsorPersonBlock-calendarBlock:read',
//      contextualHint: 'how_to_edit',
//      types: _block(
//        photoSchema.endpoint.single,
//        fileSchema.endpoint.single,
//        '/calendar_block_sections',
//        '/calendar_block_thumbs',
//        '/calendar_block_section_thumbs'
//      ).types,
//    },
  },
  
}

export default schema
