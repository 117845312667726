export const textWithSectionsPhotos = (
  photoEndpoint,
  sectionEndpoint,
  sectionThumbEndpoint
) => ({
  name: 'textWithSectionsPhotos',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS_PHOTOS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        anchorTitle: {},
      },
    },
    sections: {
      type: 'collection',
      endpoint: sectionEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
                type: 'textarea',
            },
            artist: {},
            photographer: {},
            textAfter: {
                label: 'Opis'
            },
            photoAlt: {},
//            url: {},
//            textAfter: {
//              type: 'textarea',
//            },
          },
        },
        photo: {
            type: 'image',
            description: 'T_GENERAL_PHOTO',
            endpoint: photoEndpoint,
            thumbs: sectionThumbEndpoint && {
              endpoint: sectionThumbEndpoint,
            },
            validate: ['maxSize'],
          },
//        type: {
//          type: 'media[type]',
//        },
//        photo: {
//          type: 'media[image]',
//          description: 'T_GENERAL_MEDIA_PHOTO',
//          endpoint: photoEndpoint,
//          thumbs: sectionThumbEndpoint && {
//            endpoint: sectionThumbEndpoint,
//          },
//          validate: ['maxSize'],
//        },
//        youtube: {
//          type: 'media[youtube]',
//          description: 'T_GENERAL_YOUTUBE_CODE',
//        },
      },
    },
  },
})
