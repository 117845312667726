import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { Remove, Add, KeyboardTab } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { prop } from 'core/_helpers/prop'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { constants } from 'core/pages/Pages/table/_state'
import { commonConstants } from 'core/_constants'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    position: 'relative',
    paddingLeft: 6,
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  isRedirect: {
      color: '#FF6600 !important',
      display: 'inline-block',
      marginLeft: '7px',
  }
}))

export const TitleCell = ({
  resource,
  accessor,
  editPath,
}) => {

  const classes = useStyles()

  return (
    <div className={classes.root} >
      <Link
        to={editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID] )}
        className={classes.link}
      >
        <span>
          {prop(resource, accessor)}
          {resource.isRedirect && (
            <span className={classes.isRedirect}>
                [{translate('T_MODULE_PAGES_PAGE_IS_REDIRECT')}]
            </span>
          )}
        </span>
      </Link>
    </div>
  )
}

const pageShape = {
  [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  '@id': PropTypes.string.isRequired,
}

TitleCell.propTypes = {
  resource: PropTypes.shape({
    ...pageShape,
    parent: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape(pageShape)),
  }).isRequired,
  previousResource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    parent: PropTypes.string,
  }),
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  editPath: PropTypes.string.isRequired,
  tableState: PropTypes.object.isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
}
