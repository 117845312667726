export const author = (photoEndpoint, thumbEndpoint) => ({
  name: 'author',
  label: 'T_GENERAL_BLOCK_TYPE_AUTHOR',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        authorName: {},
        authorSurname: {},
        text: {
          type: 'tinymce',
        },
        anchorTitle: {},
      },
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
  },
})
