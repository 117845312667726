//import _block from '../core/_schema/_block'
//import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/team_items',
  resource: {
    definition: 'TeamItem-teamItem:read',
    properties: {

      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          description: {
            validate: [],
            type: 'textarea',
          },
        },
      },
      teamSection: {
        description: 'T_MODULE_TEAM_SECTION',
        validate: ['required'],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/team_sections?order[ord]=desc&pagination=false`,
      },
      email: {
        validate: [],
        fullWidth: false,
      },
      phone: {
        validate: [],
        fullWidth: false,
      },
      phone2: {
        validate: [],
        fullWidth: false,
      },
      showOnContactPage:{
        type: 'boolean',
      },

    },
  },
}

export default schema
