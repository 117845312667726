//import _block from '../core/_schema/_block'
import _block from './_jobOfferBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import jobOfferSchema from './jobOffer'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/job_offers',
  preview: '/preview/job_offers/:id',
  resource: {
    definition: 'JobOffer-jobOffer:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
            //validate: ['required'],
          },
          text: {
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          alt: {},
        },
      },

      date: {
        type: 'date',
        description: 'T_GENERAL_DATE',
        validate: [],
      },

      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/job_offer_thumbs',
        },
        validate: ['maxSize'],
      },

    },
  },
  subresources: {
    block: {
      endpoint: '/job_offer_blocks',
      definition: 'JobOfferBlock-jobOfferBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/job_offer_block_sections',
        '/job_offer_block_thumbs',
        '/job_offer_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
