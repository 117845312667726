//import _block from '../core/_schema/_block'
import _block from './_calendarBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import calendarSchema from './calendar'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/calendars',
  preview: '/preview/calendars/:id',
  resource: {
    definition: 'Calendar-calendar:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          shortDescription: {
              type: 'tinymce',
          },
          text: {
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          alt: {},
        },
      },
      date: {
          type: 'date',
          validate: ['required'],
      },
//      day: {
//          validate: ['required'],
//          fullWidth: false,
//      },
//      month: {
//          validate: ['required'],
//          fullWidth: false,
//      },
//      year: {
//          validate: ['required'],
//          fullWidth: false,
//      },
//    photo jest ale jest wyłączone
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/calendar_thumbs',
        },
        validate: ['maxSize'],
      },



//      relatedCalendars: {
//        description: 'T_GENERAL_RELATED_CALENDARS',
//        validate: [],
//        type: 'multipleResource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/calendars?order[createdAt]=desc&pagination=false`,
//      },
//      relatedArticles: {
//        description: 'T_GENERAL_RELATED_ARTICLES',
//        validate: [],
//        type: 'multipleResource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/articles?order[createdAt]=desc&pagination=false`,
//      },
//      relatedEvents: {
//        description: 'T_GENERAL_RELATED_EVENTS',
//        validate: [],
//        type: 'multipleResource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/events?order[ord]=desc&pagination=false`,
//      },
//      relatedExhibitions: {
//        description: 'T_GENERAL_RELATED_EXHIBITIONS',
//        validate: [],
//        type: 'multipleResource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/exhibitions?order[ord]=desc&pagination=false`,
//      },
//      relatedKnowledges: {
//        description: 'T_GENERAL_RELATED_KNOWLEDGES',
//        validate: [],
//        type: 'multipleResource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/knowledges?order[ord]=desc&pagination=false`,
//      },

    },
  },
  subresources: {
    block: {
      endpoint: '/calendar_blocks',
      definition: 'CalendarBlock-calendarBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/calendar_block_sections',
        '/calendar_block_thumbs',
        '/calendar_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
