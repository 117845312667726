import React from 'react'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import schema from '_schema/antiqueSlide'
import parentRoutes from 'pages/Antiques/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (    
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={resource =>
        parentRoutes().show.path.replace(
          ':id',
          //resource.antique[process.env.REACT_APP_RESOURCE_ID]
          resource.antiqueUuid
        )
      }
      storeCollectionId={iri}
      fieldsFullWidth={false}
      width={900}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={Sidebar}
    />
  )
}
