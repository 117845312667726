const schema = {
  endpoint: '/package_faq_items',
  resource: {
    definition: 'PackageFaqItem-packageFaqItem:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
    },
  },
  subresources: {
    element: {
      endpoint: '/package_faq_item_elements',
      definition: 'PackageFaqItemElement-packageFaqItemElement:read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            text: {
              type: 'tinymce',
            },
          },
        },
      },
    },
  },
}

export default schema
