import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'
import articleSchema from './article'
import eventSchema from './event'
import exhibitionSchema from './exhibition'


const schema = {
  endpoint: '/banners',
  resource: {
    definition: 'Banner-banner:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          alt: {},
        },
      },
//      article: {
//        type: 'resource',
//        endpoint: `${articleSchema.endpoint}?order[createdAt]=desc&pagination=false`,
//        titleAccessor: 'translations.pl.title',
//        label: 'T_MODULE_BANNERS_RELATED_ARTICLE',
//      },

      idName: {
        type: 'string',
        description: {
          text: 'idName',
        },
      },
      url: {
          label: 'T_GENERAL_URL',
      },
      type: {
        type: 'mediaBanner[type]',
        fullWidth: false,
      },
      file: {
        type: 'mediaBanner[video]',
        fullWidth: false,
        label: 'T_GENERAL_VIDEO_FILE',
        endpoint: fileSchema.endpoint.single,
      },
      article: {
        type: 'mediaBanner[resource]',
        endpoint: `${articleSchema.endpoint}?order[ord]=desc&pagination=false`,
        titleAccessor: 'translations.pl.title',
        label: 'T_GENERAL_ARTICLE',
      },
      event: {
        type: 'mediaBanner[resource]',
        endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
        titleAccessor: 'translations.pl.title',
        label: 'T_GENERAL_EVENT',
      },
      exhibition: {
        type: 'mediaBanner[resource]',
        endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        titleAccessor: 'translations.pl.title',
        label: 'T_GENERAL_EXHIBITION',
      },
      youtube: {
        type: 'mediaBanner[youtube]',
        description: 'T_GENERAL_YOUTUBE_CODE',
      },
      photo: {
        type: 'mediaBanner[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/banner_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
      },
      
      
      
      
    },
  },
}

export default schema
