export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema
) => {
  const modifiedProperties = properties

  if (isOpenform) {
    return {
      ...properties,
      homePagePosition: {
        type: 'number',
        fullWidth: false,
        description: {
          text: 'T_HOMEPAGE_POSITION',
          color: '#cc0000',
        },
      },
//      isStatable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_STATABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
//      isEditable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_EDITABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
//      isDeletable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_DELETABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
    }
  }

//  if (resource && !resource.isStatable) {
//    delete modifiedProperties.stat
//  }

  return modifiedProperties
}
