import React from 'react'
import { Title } from 'core/components/Title'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import schema from 'core/_schema/packageFormItem'
import parentRoutes from 'core/pages/PackageForms/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties => {
        let modifiedProperties = { ...properties }
        delete modifiedProperties.type

        return modifiedProperties
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={resource =>
        parentRoutes().show.path.replace(
          ':id',
          resource.parent[process.env.REACT_APP_RESOURCE_ID]
        )
      }
      storeCollectionId={iri}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      width={700}
      header={resource => (
        <Title>
          {translate(
            `T_MODULE_PACKAGE_FORM_ITEM_TYPE_${resource.type.toUpperCase()}`
          )}
        </Title>
      )}
    >
      {resource =>
        ['radio', 'checkbox'].includes(resource.type) && (
          <EmbeddedCollection
            endpoint={schema.subresources.element.endpoint}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.element.properties}
            definitionSchema={
              definitions[schema.subresources.element.definition]
            }
            headerTitle={translate(
              'T_MODULE_PACKAGE_FORM_ITEM_VARIANTS_HEADER_TITLE'
            )}
            expandTitle={translate(
              'T_MODULE_PACKAGE_FORM_ITEM_VARIANTS_EXPAND_TITLE'
            )}
            statable={true}
          />
        )
      }
    </Form>
  )
}
