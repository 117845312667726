const schema = {
  endpoint: '/knowledge_genres',
  resource: {
    definition: 'KnowledgeGenre-knowledgeGenre:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
        },
      },
      
      
//      eventAgeGroups: {
//        description: 'T_GENERAL_RELATED_EVENT_AGE_GROUPS',
//        validate: [],
//        type: 'multipleResource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${articleSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/event_age_groups?order[ord]=desc&pagination=false`,
//      },
    },
  },
}

export default schema
