import adminSchema from 'core/_schema/admin'
import adminGroupSchema from 'core/_schema/adminGroup'
import loginEntry from 'core/_schema/loginEntry'
import registryEntry from 'core/_schema/registryEntry'
import page from 'core/_schema/page'

import translatorEntry from 'core/_schema/translatorEntry'
import packageFile from 'core/_schema/packageFile'
import packageGallery from 'core/_schema/packageGallery'
import packageFaq from 'core/_schema/packageFaq'
import packageForm from 'core/_schema/packageForm'

import config from 'core/_schema/config'
import configContact from './configContact'
import configVolunteering from './configVolunteering'

import antique from './antique'
import antiqueSlide from './antiqueSlide'
import article from './article'
import banner from './banner'

import calendar from './calendar'
import calendarSlide from './calendarSlide'
import cooperationMessage from './cooperationMessage'
import cooperationType from './cooperationType'
import coordinator from './coordinator'

import educationTarget from './educationTarget'
import educationAgeGroup from './educationAgeGroup'
import educationActivity from './educationActivity'
import event from './event'
import eventType from './eventType'
import eventDate from './eventDate'
import eventAgeGroup from './eventAgeGroup'
import exhibition from './exhibition'
import exhibitionType from './exhibitionType'

import guide from './guide'
import guideLanguage from './guideLanguage'
import guidePath from './guidePath'

import knowledge from './knowledge'
import knowledgeEpoch from './knowledgeEpoch'
import knowledgeGenre from './knowledgeGenre'
import knowledgePublicationType from './knowledgePublicationType'
import knowledgeTarget from './knowledgeTarget'

import visitPlanMessage from './visitPlanMessage'

import zonk from './zonk'
import menu from './menu'
import menuItem from './menuItem'
import teamItem from './teamItem'
import teamSection from './teamSection'

import procurement from './procurement'
import saleOffer from './saleOffer'
import offerInquiry from './offerInquiry'
import publicTender from './publicTender'
import marketAdvice from './marketAdvice'
import jobOffer from './jobOffer'




const resources = () => ({
  [page.endpoint]: 'Pages',

  [article.endpoint]: 'Articles',
  [antique.endpoint]: 'Antiques',
  
  [banner.endpoint]: 'Banners',
  
  [calendar.endpoint]: 'Calendars',
  [calendarSlide.endpoint]: 'Calendar slides', 
  
  [coordinator.endpoint]: 'Coordinators',

  [educationActivity.endpoint]: 'Education activities',
  [educationAgeGroup.endpoint]: 'Education age groups',
  [educationTarget.endpoint]: 'Education targets',
  [event.endpoint]: 'Events',
  [eventAgeGroup.endpoint]: 'Event age groups',
  [eventDate.endpoint]: 'Event dates',
  [eventType.endpoint]: 'Event types',
  [exhibition.endpoint]: 'Exhibitions',
  [exhibitionType.endpoint]: 'Exhibition types',
  
  [guide.endpoint]: 'Guides',
  [guideLanguage.endpoint]: 'Guide languages',
  [guidePath.endpoint]: 'Guide paths',
  
  [knowledge.endpoint]: 'Knowledge',
  [knowledgeEpoch.endpoint]: 'Knowledge epochs',
  [knowledgeGenre.endpoint]: 'Knowledge genres',
  [knowledgePublicationType.endpoint]: 'Knowledge publication types',
  [knowledgeTarget.endpoint]: 'Knowledge targets',
  
  [menu.endpoint]: 'Menus',
  [menuItem.endpoint]: 'Menu items',
  
  [teamItem.endpoint]: 'Team items',
  [teamSection.endpoint]: 'Team sections',

  [visitPlanMessage.endpoint]: 'Visit plan messages',
  [cooperationMessage.endpoint]: 'Cooperation messages',
  [cooperationType.endpoint]: 'Cooperation types',
  
  [procurement.endpoint]: 'BIP - Procurement',
  [saleOffer.endpoint]: 'BIP - Sale offer',
  [offerInquiry.endpoint]: 'BIP - Offer inquiry',
  [publicTender.endpoint]: 'BIP - Public Tender',
  [marketAdvice.endpoint]: 'BIP - Market Advice',
  [jobOffer.endpoint]: 'BIP - Job Offer',
  

  [packageFile.endpoint]: 'File packages',
  [packageGallery.endpoint]: 'Gallery packages',
  [packageFaq.endpoint]: 'Faq packages',
  //[packageForm.endpoint]: 'Form packages',

  
  [configContact.endpoint]: 'Config Contacts',
  [configVolunteering.endpoint]: 'Config Volunteerings',
  [config.endpoint]: 'Config',
  
  [translatorEntry.endpoint]: 'Translator entries',
  
  [adminSchema.endpoint]: 'Administrators',
  [adminGroupSchema.endpoint]: 'Administrator Groups',
  [loginEntry.endpoint]: 'Login entries',
  [registryEntry.endpoint]: 'Registry entries',
  
  

  
})

export default resources
