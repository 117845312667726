export const textWithSectionsLinks = (
  photoEndpoint,
  sectionEndpoint,
  sectionThumbEndpoint
) => ({
  name: 'textWithSectionsLinks',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS_LINKS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        anchorTitle: {},
      },
    },
    sections: {
      label: 'Edycja listy sekcji',
      type: 'collection',
      endpoint: sectionEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
                type: 'textarea',
            },
            url: {},
//            textBefore: {
//              type: 'textarea',
//            },
//            textAfter: {
//              type: 'textarea',
//            },
          },
        },
//        type: {
//          type: 'media[type]',
//        },
//        photo: {
//          type: 'media[image]',
//          description: 'T_GENERAL_MEDIA_PHOTO',
//          endpoint: photoEndpoint,
//          thumbs: sectionThumbEndpoint && {
//            endpoint: sectionThumbEndpoint,
//          },
//          validate: ['maxSize'],
//        },
//        youtube: {
//          type: 'media[youtube]',
//          description: 'T_GENERAL_YOUTUBE_CODE',
//        },
      },
    },
  },
})
