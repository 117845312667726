const schema = {
  endpoint: '/visit_plan_messages',
  resource: {
    definition: 'VisitPlanMessage-visitPlanMessage:read',
    properties: {
      
      title: {
        //validate: ['required'],
        fullWidth: false,
      },
      email: {
        validate: ['required'],
        fullWidth: false,
      },
      text: {
        type: 'textarea',
        validate: ['required'],
      },
      isReaded:{
        type: 'boolean',
      },
    },
  },
}

export default schema
