//import _block from '../core/_schema/_block'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'


const schema = {
  endpoint: '/sale_offer_slides',
  resource: {
    definition: 'SaleOfferSlide-saleOfferSlide:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          alt: {},
          
//          slug: {},
        },
      },

      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/sale_offer_slide_thumbs',
        },
        validate: ['maxSize'],
      },

    
      
    },
  },
  
}

export default schema
