import packageFaqSchema from 'core/_schema/packageFaq'

export const packageFaqSimple = () => ({
  name: 'packageFaqSimple',
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ_SIMPLE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        anchorTitle: {},
//        subtitle: {},
//        text: {
//          type: 'tinymce',
//        },
      },
    },
    packageFaq: {
      type: 'resource',
      endpoint: `${packageFaqSchema.endpoint}?pagination=false`,
      titleAccessor: 'title',
    },
  },
})
