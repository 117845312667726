import React, { useState, useMemo, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { fetchFrontPreview } from 'core/_helpers/fetchFrontPreview'
import { translate } from 'core/_helpers/translate'
import { store } from 'core/_store'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: 'calc(100vh - 64px)',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

export const Preview = ({
  path,
  match,
  endpoint,
  onLoad = fetchFrontPreview,
}) => {
  const [state, setState] = useResourceState()

  useResourceFetch(
    `${endpoint}/${match.params.id}`,
    state.resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const [response, setResponse] = useState(null)

  const classes = useStyles()

  const url = useMemo(() => path.replace(':id', match.params.id), [path, match])

  const jwtToken = store.getState().auth.data?.token

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const headers = {
      Authorization: `Bearer ${jwtToken}`
    }
    
    
    onLoad(
      `${process.env.REACT_APP_FRONT_ENTRYPOINT}${url}`,
      { signal, headers },
      setResponse
    )

    return () => controller.abort()
  }, [url, onLoad, setResponse])

  return (
    <div className={classes.root}>
      {response === null ? (
        <Loader align="center" marginTop={15} />
      ) : (
        <iframe
          title={translate('T_GENERAL_PREVIEW')}
          src={response}
          frameBorder={0}
          className={classes.iframe}
        ></iframe>
      )}
    </div>
  )
}

Preview.propTypes = {
  path: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  endpoint: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
}
