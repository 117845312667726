import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { prop } from 'core/_helpers/prop'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  customTooltip: {
    color: theme.elements.tooltip.color,
    backgroundColor: theme.elements.tooltip.background,
    border: '1px solid #C4CBD8',
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    fontSize: '13px',
    marginTop: -27,
    padding: 0,
  },
  wrapper: {
    display: 'flex',
    '& > div': {
      padding: 10,
    },
  },
  iconInside: {
    backgroundColor: theme.palette.primary.main,
    padding: 2,
    marginLeft: 2,
    color: theme.elements.tooltip.background,
  },
  icon: {
    color: theme.palette.success.main,
  },
}))

export const Timestamp = ({
  resource,
  createdAtAccessor = 'createdAt',
  updatedAtAccessor = 'updatedAt',
}) => {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
      }}
      placement="bottom-end"
      title={
        <div className={classes.wrapper}>
          <div>
            {createdAtAccessor && (
              <div>
                {translate('T_GENERAL_CREATED_AT')}:&nbsp;
                {prop(resource, createdAtAccessor)}
              </div>
            )}
            {updatedAtAccessor && (
              <div>
                {translate('T_GENERAL_UPDATED_AT')}:&nbsp;
                {prop(resource, updatedAtAccessor)}
              </div>
            )}
          </div>
          <span className={classes.iconInside}>
            <InfoOutlined />
          </span>
        </div>
      }
    >
      <InfoOutlined className={classes.icon} />
    </Tooltip>
  )
}

Timestamp.propTypes = {
  resource: PropTypes.object.isRequired,
  createdAtAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  updatedAtAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}
