import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import randomHash from 'random-hash';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { validate } from 'core/_helpers/validate';
import { translate } from 'core/_helpers/translate';

const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}));

export const TimeType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash());

  const handleChange = e => {
    const value = e.target.value;
    setValue(name, value || null);
    validateField(value);
  };

const validateField = useCallback(
  value => {
    if (!validators || !value) {
      setError(name, false);
      return;
    }

    // Dodaj walidację formatu godziny i minuty (hh:mm)
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
      setError(name, 'Proszę wprowadzić godzinę w formacie "hh:mm"');
    } else {
      const valid = validate(validators, value);
      setError(name, !valid.result && valid.message);
    }
  },
  [validators, setError, name]
);

  useEffect(() => {
    validateField(initialValue);
  }, [validateField, initialValue]);

  const classes = useStyles();

  return (
    <>
      <TextField
        id={id}
        name={name}
        type="time-local"
        label={
          translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={value ? value.replace(' ', 'T') : ''}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label.color
            ? {
                style: {
                  color: label.color,
                },
                shrink: true,
              }
            : {
                shrink: true,
              }
        }
        fullWidth={fullWidth}
        variant="standard"
        placeholder="hh:mm"
      />
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </>
  );
};

TimeType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};
