import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  linkWithIcon: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  linkWithoutIcon: {
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const EditLink = ({
  resource,
  disabled = false,
  editPath = null,
  isIcon = false,
}) => {
  const classes = useStyles()
  return (
    <>
      {disabled ? (
        isIcon ? (
          <EditOutlined className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate('T_GENERAL_EDIT')}
          </span>
        )
      ) : (
        <Link
          to={
            editPath
              ? editPath.replace(
                  ':id',
                  resource[process.env.REACT_APP_RESOURCE_ID]
                )
              : resource['@id']
          }
          className={isIcon ? classes.linkWithIcon : classes.linkWithoutIcon}
        >
          {isIcon ? (
            <Tooltip title={translate('T_GENERAL_EDIT')}>
              <EditOutlined />
            </Tooltip>
          ) : (
            translate('T_GENERAL_EDIT')
          )}
        </Link>
      )}
    </>
  )
}

EditLink.propTypes = {
  resource: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  editPath: PropTypes.string,
  isIcon: PropTypes.bool,
}
