import React, { useCallback, useMemo }  from 'react'
import { Form } from 'core/pages'
import schema from '_schema/coordinator'
import routes from './routes'

import { BlockCollection } from 'core/components/block'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'

import packageFileSchema from 'core/_schema/packageFile'
import packageGallerySchema from 'core/_schema/packageGallery'
import packageFaqSchema from 'core/_schema/packageFaq'
import packageFormSchema from 'core/_schema/packageForm'


export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const packageResourcesMapping = useMemo(
    () => ({
      packageFile: packageFileSchema.endpoint,
      packageGallery: packageGallerySchema.endpoint,
      packageFaq: packageFaqSchema.endpoint,
      packageForm: packageFormSchema.endpoint,
    }),
    []
  )

  const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)
  
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      width="100%"
    >
            
    </Form>
  )
}
