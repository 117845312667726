import _block from '../core/_schema/_block'
import photoSchema from '../core/_schema/photo'

import guideLanguageSchema from './guideLanguage'
import guidePathSchema from './guidePath'

const schema = {
  endpoint: '/guides',
  resource: {
    definition: 'Guide-guide:read',
    properties: {
      firstName: {
        validate: ['required'],
      },
      lastName: {
        validate: ['required'],
      },
      licenceNumber: {
        //validate: ['required'],
      },
      email: {
        validate: ['required'],
      },
      phone: {
        validate: ['required'],
      },
      languages: {
        description: 'T_GENERAL_GUIDE_LANGUAGES',
        validate: ['required'],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${guideLanguageSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      paths: {
        description: 'T_GENERAL_GUIDE_PATHS',
        validate: ['required'],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${guidePathSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      isVisibleOnGuides:{
        type: 'boolean',
      },
      isLoggableToGuides:{
        type: 'boolean',
      },
      plainPassword: {
        type: 'passwordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        //validate: ['required', 'password'],
        validate: [],
      },
      
      photo: {
        type: 'image',
        description: 'T_GENERAL_GUIDE_IMAGE',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/guide_thumbs',
        },
        validate: ['maxSize'],
      },
      
      
     
//      translations: {
//        type: 'translation',
//        properties: {
//          title: {
//            validate: ['required'],
//          },
//          text: {
//            type: 'textarea',
//          },
//          slug: {},
//        },
//      },
//      map: {
//        type: 'map',
//        description: 'T_GENERAL_MAP',
//        hint: 'T_GENERAL_MAP_HINT',
//      },
//      lead: {
//        type: 'textarea',
//        description: 'T_GENERAL_LEAD',
//        hint: 'T_GENERAL_LEAD_HINT',
//        validate: ['required'],
//      },
//      description: {
//        type: 'tinymce',
//        description: 'T_GENERAL_DESCRIPTION',
//        hint: 'T_GENERAL_DESCRIPTION_HINT',
//        validate: ['required'],
//      },
//      date: {
//        type: 'date',
//        description: 'T_GENERAL_DATE',
//        validate: ['required'],
//      },
//      plannedPublishDateTime: {
//        type: 'datetime',
//        description: 'T_GENERAL_PLANNED_PUBLISH_DATE_TIME',
//        fullWidth: false,
//        validate: ['required'],
//      },
//
      

      
    },
  },
  
}

export default schema
