import React from 'react'
import { Route } from 'react-router-dom'
import { AssignmentIndOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from 'core/_schema/loginEntry'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: schema.endpoint,
        exact: true,
        icon: AssignmentIndOutlined,
      },
    },
    {
      title: 'Login entries',
      resourceAccess: schema.endpoint,
    }
  )

export default routes
