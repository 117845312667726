//import _block from '../core/_schema/_block'
//import photoSchema from '../core/_schema/photo'

import pageSchema from '../core/_schema/page'
import articleSchema from '../_schema/article'
import eventSchema from '../_schema/event'
import exhibitionSchema from '../_schema/exhibition'


const schema = {
  endpoint: '/menu_items',
  resource: {
    definition: 'MenuItem-menuItem:read',
    properties: {

      translations: {
        type: 'translation',
        properties: {
          title: {
              //validate: ['required'],
              fullWidth: false
          },
          label: {
              fullWidth: false
          },
          description: {},
          additionalButtonLabel: {
              fullWidth: true
          },
        },
      },
      targetBlank:{
        type: 'boolean',
      },
      type: {
        type: 'choice',
        choices: {
            external_url: 'Link zewnętrzny',
            page: 'Podstrona',
            article: 'Artykuł',
            event: 'Wydarzenie',
            exhibition: 'Wystawa',
        },
        validate: ['required'],
      },
      externalUrl: {
          fullWidth: true
      },
      additionalButtonUrl: {
        fullWidth: true
      },
      page: {
        description: 'T_MODULE_MENU_RELATED_PAGE',
        validate: [],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${pageSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      article: {
        description: 'T_MODULE_MENU_RELATED_ARTICLE',
        validate: [],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${articleSchema.endpoint}?order[createdAt]=desc&pagination=false`,
      },
      event: {
        description: 'T_MODULE_MENU_RELATED_EVENT',
        validate: [],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      exhibition: {
        description: 'T_MODULE_MENU_RELATED_EXHIBITION',
        validate: [],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
      },

      
    
//      teamSection: {
//        description: 'T_MODULE_MENU',
//        validate: ['required'],
//        type: 'resource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/menus?order[ord]=desc&pagination=false`,
//      },
    },
  },
}

export default schema
