//import _block from '../core/_schema/_block'  // bloczki core-owe/defaultowe
import _block from './_educationActivityBlock'                  // bloczki customowe
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

const schema = {
  endpoint: '/education_activities',
  resource: {
    definition: 'EducationActivity-educationActivity:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'textarea',
          },
          subtitle: {},
          description: {
            type: 'textarea',
          },
          activityTarget: {},
          activityLocation: {},
          activityCosts: {},
          activityTerm: {},
          activityDuration: {},
          activityRemarks: {
            type: 'textarea',
          },

          buttonUrl: {},
          buttonLabel: {},
        },
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/education_activity_blocks',
      definition: 'EducationActivityBlock-educationActivityBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/education_activity_block_sections',
        '/education_activity_block_thumbs',
        '/education_activity_block_section_thumbs'
      ).types,
    },
  },
}

export default schema


