import _block from '../core/_schema/_block'
import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/event_types',
  resource: {
    definition: 'EventType-eventType:read',
    properties: {
//      firstName: {
//        validate: ['required'],
//      },
//      lastName: {
//        validate: ['required'],
//      },
//      email: {
//        validate: ['required'],
//      },
//      phone: {
//        validate: ['required'],
//      },

      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
//          text: {
//            type: 'textarea',
//          },
//          slug: {},
        },
      },
//      map: {
//        type: 'map',
//        description: 'T_GENERAL_MAP',
//        hint: 'T_GENERAL_MAP_HINT',
//      },
//      lead: {
//        type: 'textarea',
//        description: 'T_GENERAL_LEAD',
//        hint: 'T_GENERAL_LEAD_HINT',
//        validate: ['required'],
//      },
//      description: {
//        type: 'tinymce',
//        description: 'T_GENERAL_DESCRIPTION',
//        hint: 'T_GENERAL_DESCRIPTION_HINT',
//        validate: ['required'],
//      },
//      date: {
//        type: 'date',
//        description: 'T_GENERAL_DATE',
//        validate: ['required'],
//      },
//      plannedPublishDateTime: {
//        type: 'datetime',
//        description: 'T_GENERAL_PLANNED_PUBLISH_DATE_TIME',
//        fullWidth: false,
//        validate: ['required'],
//      },
//
//      photo: {
//        type: 'image',
//        description: 'T_GENERAL_GUIDE_IMAGE',
//        endpoint: photoSchema.endpoint.single,
//        endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/guide_thumbs',
//        },
//        validate: ['maxSize'],
//      },

    },
  },

}

export default schema
