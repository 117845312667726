import {
  text,
  textWithPhoto,
  textWithQuotation,
  textWithLink,
  textWithSections,
  packageFile,
  packageGallery,
  packageFaq,
  packageForm,
} from 'core/_schema/_blocks'

const schema = (
  photoEndpoint,
  sectionEndpoint,
  blockThumbEndpoint,
  sectionThumbEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    //textWithQuotation(),
    textWithLink(photoEndpoint, blockThumbEndpoint),
    textWithSections(photoEndpoint, sectionEndpoint, sectionThumbEndpoint),
    packageFile(),
    packageGallery(),
    packageFaq(),
    packageForm(),
  ],
})

export default schema
