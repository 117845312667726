const schema = {
  endpoint: '/cooperation_messages',
  resource: {
    definition: 'CooperationMessage-cooperationMessage:read',
    properties: {
      
      cooperationType: {
        description: 'T_MODULE_COOPERATION_TYPE',
        validate: ['required'],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/cooperation_types?order[ord]=desc&pagination=false`,
      },
      
      firstName: {
        validate: ['required'],
        fullWidth: false,
      },
      lastName: {
        validate: ['required'],
        fullWidth: false,
      },
      email: {
        validate: ['required'],
        fullWidth: false,
      },
      text: {
        type: 'textarea',
        validate: ['required'],
      },
      isReaded:{
        type: 'boolean',
      },

      

    },
  },
}

export default schema
