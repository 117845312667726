import React from 'react'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import schema from 'core/_schema/packageFaqItem'
import parentRoutes from 'core/pages/PackageFaqs/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={resource =>
        parentRoutes().show.path.replace(
          ':id',
          resource.parent[process.env.REACT_APP_RESOURCE_ID]
        )
      }
      storeCollectionId={iri}
      fieldsFullWidth={false}
      width={600}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={Sidebar}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.element.endpoint}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.element.properties}
        definitionSchema={definitions[schema.subresources.element.definition]}
        headerTitle={translate(
          'T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS_HEADER_TITLE'
        )}
        expandTitle={translate(
          'T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS_EXPAND_TITLE'
        )}
        panelTitle={translate('Question')}
      />
    </Form>
  )
}
