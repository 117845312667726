import React from 'react'
import { translate } from 'core/_helpers/translate'
import { Divider, Typography } from '@material-ui/core'

export const Title = ({ label }) => {
  return (
    <>
      <Typography
        variant={'h4'}
        style={{
          marginTop: 50,
        }}
      >
        {translate(label.text || label)}
      </Typography>
      <Divider
        style={{
          marginBottom: 20,
          maxWidth: 300,
        }}
      />
    </>
  )
}
