import logoKitSchema from '_schema/logoKit'

export const logoKit = () => ({
  name: 'logoKit',
  label: 'T_GENERAL_BLOCK_TYPE_LOGO_KIT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        anchorTitle: {},
      },
    },
    logoKit: {
      type: 'resource',
      endpoint: `${logoKitSchema.endpoint}?pagination=false`,
      titleAccessor: 'title',
    },
  },
})
