import React from 'react'
import PropTypes from 'prop-types'
import { FileType } from 'core/components/form/fields/file/FileType'

//export const MediaBannerImageType = ({ additional, label, ytProperty, ...rest }) => (
export const MediaBannerVideoType = ({ additional, label, type, ...rest }) => {
    return additional.media === 'video' ? (
        <FileType      
          label={label}
          {...rest}
        />
    ) : null
}

MediaBannerVideoType.propTypes = {
  additional: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  type: PropTypes.string.isRequired, 
}
