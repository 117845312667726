export const textWithQuotation = () => ({
  name: 'textWithQuotation',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_QUOTATION',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymce',
        },
        quotation: {
          type: 'textarea',
        },
        authorName: {},
        authorSurname: {},
        authorRemarks: {},
        anchorTitle: {},
      },
    },
  },
})
