export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: 'textWithPhoto',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {},
        text: {
          type: 'tinymce',
        },
        anchorTitle: {},
      },
    },
    mediaPosition: {
      type: 'choice',
      choices: {
        left: 'T_GENERAL_BLOCK_MEDIA_POSITION_LEFT',
        top: 'T_GENERAL_BLOCK_MEDIA_POSITION_TOP',
        right: 'T_GENERAL_BLOCK_MEDIA_POSITION_RIGHT',
      },
      validate: ['required'],
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
  },
})
