
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

const schema = {
  endpoint: '/visit_infos',
  resource: {
    definition: 'VisitInfo-visitInfo:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            label: 'Tytuł', 
            type: 'textarea',
          },
          text: {
            validate: [],
            label: 'Treść',
            type: 'textarea',
          },
          fileTitle: {
            validate: [],
            label: 'Opis pliku',
            type: 'string',
          },
        },
      },
      side: {
        type: 'choice',
        choices: {
          'LEFT': 'lewa',
          'RIGHT': 'prawa',
        },
        validate: ['required'],
        fullWidth: false,
      },      
      file: {
        type: 'file',
        label: 'Plik do pobrania',
        description: 'T_GENERAL_FILE',
        endpoint: fileSchema.endpoint.single,
        validate: ['maxSize'],
      },
      latitude: {
        validate: [],
        type: 'string',
        fullWidth: false
      },
      longitude: {
        validate: [],
        type: 'string',
        fullWidth: false
      },
      
//      działa ale jest ukryte      
//      photo: {
//        type: 'image',
//        description: 'T_GENERAL_PHOTO',
//        endpoint: photoSchema.endpoint.single,
//        //endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/visit_info_thumbs',
//        },
//        validate: ['maxSize'],
//      },
    },
  },
  subresources: {
//    block: {
//      endpoint: '/calendar_blocks',
//      definition: 'VisitInfoBlock-calendarBlock:read',
//      contextualHint: 'how_to_edit',
//      types: _block(
//        photoSchema.endpoint.single,
//        fileSchema.endpoint.single,
//        '/calendar_block_sections',
//        '/calendar_block_thumbs',
//        '/calendar_block_section_thumbs'
//      ).types,
//    },
  },
  
}

export default schema
