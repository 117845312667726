import React from 'react'
import { Form } from 'core/pages'

import schema from '_schema/configTomorrowsPatriotism'
import routes from './routes'

import { Settings } from './Settings'

export const Config = ({ schema: definitions, ...rest }) => {
  const { match } = rest


  const iri = `${schema.endpoint}/1`


  return (
    <div>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        width="100%"
        sidebar={false}
        sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
        SidebarComponent={()=>{
            return (<Settings {...rest} />)
        }}
      />
    </div>
  )
}
