import { useReducer, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { constants } from 'core/components/embedded/_state'
import buildUrl from 'build-url'

const initialState = {
  items: [],
  init: true,
  isFetching: true,
}

export const useEmbeddedCollectionFetch = (reducer, endpoint, pid) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: constants.FETCH_START })

    const url = buildUrl(endpoint, {
      queryParams: { [`parent.${process.env.REACT_APP_RESOURCE_ID}`]: pid },
    })

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        dispatch({
          type: constants.FETCH_SUCCESS,
          payload: { items: response['hydra:member'] },
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, pid])

  return [state, dispatch]
}
