import React from 'react'
import PropTypes from 'prop-types'
import { ResourceType } from 'core/components/form/fields/ResourceType'

export const MediaBannerResourceType = ({ additional, ...rest }) => {
    return additional.media === rest.name ? (
        <ResourceType
          syncWithAdditional={true}
          {...rest}
        />
    ) : null
}

MediaBannerResourceType.propTypes = {
  additional: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  
}
