import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { BlockCollection } from 'core/components/block'
import { Form } from 'core/components/form'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { NotFound } from 'core/pages/NotFound'
import { applyProperties } from 'core/_helpers/applyProperties'
import { prop } from 'core/_helpers/prop'
import { translate } from 'core/_helpers/translate'
import { commonConstants } from 'core/_constants'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  title: {
    paddingBottom: 15,
  },
}))

export const VersionView = ({
  endpoint,
  uuid,
  definitionSchema,
  customResourceSchema,
  currentPath,
  versionableCollectionTitle,
  versionableCollectionPath,
  versionableEditPath,
  versionCollectionPath,
  titleAccessor = 'title',
  blockable = false,
  blockEndpoint = null,
  blockDefinitionSchema = null,
  blockTypes = [],
  wrapWithPaper = true,
}) => {
  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  const iri = useMemo(() => `${endpoint}/${uuid}`, [endpoint, uuid])

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const dispatch = useDispatch()

  const isNotVersion = useMemo(() => resource && !resource.versionable, [
    resource,
  ])

  useEffect(() => {
    if (isNotVersion) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }
  }, [isNotVersion, dispatch])

  const properties = applyProperties(
    customResourceSchema.properties,
    definitionSchema.properties,
    'missing'
  )

  useEffect(() => {
    if (!resource || isNotVersion) {
      return
    }

    dispatch({
      type: commonConstants.ADD_CUSTOM_BREADCRUMBS,
      payload: {
        path: currentPath,
        breadcrumbs: [
          {
            title: versionableCollectionTitle,
            path: versionableCollectionPath,
          },
          {
            title: `${versionableCollectionTitle} (${prop(
              resource.versionable,
              titleAccessor
            )})`,
            path: versionableEditPath.replace(
              ':id',
              resource.versionable[process.env.REACT_APP_RESOURCE_ID]
            ),
          },
          {
            title: `${translate('T_GENERAL_RECORD_VERSION_COLLECTION')} (${prop(
              resource,
              titleAccessor
            )})`,
            path: versionCollectionPath.replace(
              ':id',
              resource.versionable[process.env.REACT_APP_RESOURCE_ID]
            ),
          },
          {
            title: 'T_GENERAL_RECORD_VERSION_VIEW',
          },
        ],
      },
    })
  }, [
    resource,
    isNotVersion,
    currentPath,
    versionableCollectionTitle,
    versionableCollectionPath,
    versionableEditPath,
    versionCollectionPath,
    titleAccessor,
    dispatch,
  ])

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  const classes = useStyles()

  return isFetching ? (
    <WrapComponent>
      <Loader />
    </WrapComponent>
  ) : isNotVersion ? (
    <Redirect to={versionableCollectionPath} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container}>
      <Title className={classes.title}>
        {translate('T_GENERAL_VERSION_CREATED_AT')} {resource.createdAt}
      </Title>
      <WrapComponent withPadding={false}>
        <Form
          readOnly={true}
          url={iri}
          method="PUT"
          properties={properties}
          resource={resource}
          width={800}
          fieldsFullWidth={false}
        />
        {blockable && (
          <BlockCollection
            endpoint={blockEndpoint}
            pid={uuid}
            parentIri={iri}
            definitionSchema={blockDefinitionSchema}
            types={blockTypes}
            statable={true}
            disabled={true}
            key={iri}
          />
        )}
      </WrapComponent>
    </div>
  )
}

VersionView.propTypes = {
  endpoint: PropTypes.string.isRequired,
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
  versionableCollectionTitle: PropTypes.string.isRequired,
  versionableCollectionPath: PropTypes.string.isRequired,
  versionableEditPath: PropTypes.string.isRequired,
  versionCollectionPath: PropTypes.string.isRequired,
  titleAccessor: PropTypes.string,
  blockable: PropTypes.bool,
  blockEndpoint: PropTypes.string,
  blockDefinitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }),
  blockTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
  wrapWithPaper: PropTypes.bool,
}
