export const textWithPhotoAndAlt = (photoEndpoint, thumbEndpoint) => ({
  name: 'textWithPhotoAndAlt',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        text: {
          type: 'tinymce',
        },
        photoTitle: {
            type: 'string'
        },
        photoAlt: {
            type: 'string'
        },
        buttonTitle: {
            label: 'Etykieta dodatkowego buttona',
        },
        anchorTitle: {},
      },
    },
    photoAuthor: {
        type: 'string'
    },
    buttonLink: {
      label: 'Link dodatkowego buttona',
      fullWidth: true,
    },
    mediaPosition: {
      type: 'choice',
      choices: {
        left: 'T_GENERAL_BLOCK_MEDIA_POSITION_LEFT',
        //top: 'T_GENERAL_BLOCK_MEDIA_POSITION_TOP',
        right: 'T_GENERAL_BLOCK_MEDIA_POSITION_RIGHT',
        fullWidth: 'T_GENERAL_BLOCK_MEDIA_POSITION_FULL_WIDTH',
        fullWidthMargin: 'T_GENERAL_BLOCK_MEDIA_POSITION_FULL_WIDTH_MARGIN',
      },
      validate: ['required'],
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
  },
})
