import React from 'react'
import { Route } from 'react-router-dom'
import { PhonelinkSetupOutlined } from '@material-ui/icons'
//import { Settings } from './Settings'

import { SchemableComponent } from 'core/components/SchemableComponent'

import { Config } from './Config'


const routes = () => ({
  index: {
    title: 'T_MODULE_CONFIG_GUIDE',
    type: Route,

    // tak można zapiąć samodzielny moduł
    // render: props => <Settings {...props} />,
    
    render: props => (
      <SchemableComponent path="definitions" component={Config} {...props} />
    ),

    path: '/config_guide',
    exact: true,
    icon: PhonelinkSetupOutlined,
  },
})

export default routes
