import React, { useMemo } from 'react'
import { NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { EditHeader } from 'core/components/EditHeader'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/educationTarget'
import routes from './routes'
import itemSchema from '_schema/educationAgeGroup'
import itemRoutes from 'pages/EducationAgeGroups/routes'

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '73%',
      },
      {
        ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`),
        width: '15%',
      },
    ],
    []
  )

  const itemDefaultFilters = {
    [`educationTarget.${process.env.REACT_APP_RESOURCE_ID}`]: match.params.id,
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_EDUCATION_AGE_GROUPS_NEW_BUTTON')}
        buttonPath={`${itemRoutes().new.path}?pid=${match.params.id}`}
      >
      <EditHeader 
        resource={resource} 
        editPath={routes().edit.path} 
      />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        tableTitle={translate('T_MODULE_EDUCATION_TARGETS_AGE_GROUPS_LIST')}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        storeCollectionId={parentIri}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultFilters={itemDefaultFilters}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().show.path}
        autoWidth={false}
      />
    </Paper>
  )
}
