//import _block from '../core/_schema/_block'
//import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/holidays',
  resource: {
    definition: 'Holiday-holiday:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
          },
        },
      },

      date: {
        type: 'date',
        description: 'T_GENERAL_DATE',
        validate: ['required'],
      },
      
      isClosed:{
        type: 'boolean',
      },
      isFreeEntry:{
        type: 'boolean',
      },
      showDate:{
        type: 'boolean',
      },
      
      timeFrom: {
        type: 'time',
        fullWidth: false,
      },
      timeTo: {
        type: 'time',
        fullWidth: false,
      },
      
    },
  },

}

export default schema
