

export const openingHours = () => ({
  name: 'openingHours',
  label: 'T_GENERAL_BLOCK_TYPE_OPENING_HOURS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        anchorTitle: {},
      },
    },
  },
})
