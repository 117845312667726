export const textWithTwoButtonLinks = () => ({
  name: 'textWithTwoButtonLinks',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_TWO_BUTTON_LINKS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        //subtitle: {},
        text: {
          type: 'tinymce',
        },
        buttonTitle: {},
        title2: {
            type: 'textarea',
        },
        //subtitle2: {
        //    label:"Podtytuł 2"
        //},
        text2: {
          type: 'tinymce',
        },
        buttonTitle2: {},
        anchorTitle: {},
      },
    },
    buttonLink: {
      fullWidth: true,
    },
    buttonLink2: {
      fullWidth: true,
    },
  },
})
