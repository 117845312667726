//import _block from '../core/_schema/_block'
//import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/opening_hours',
  resource: {
    definition: 'OpeningHour-openingHour:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          text: {
            type: 'textarea',
          },
        },
      },
        showHours:{
          type: 'boolean',
        },
        useForTodayOpen:{
          type: 'boolean',
        },
        timeFrom1: {
          type: 'time',
          fullWidth: false,
        },
        timeTo1: {
          type: 'time',
          fullWidth: false,
        },
        timeFrom2: {
          type: 'time',
          fullWidth: false,
        },
        timeTo2: {
          type: 'time',
          fullWidth: false,
        },
        timeFrom3: {
          type: 'time',
          fullWidth: false,
        },
        timeTo3: {
          type: 'time',
          fullWidth: false,
        },
        timeFrom4: {
          type: 'time',
          fullWidth: false,
        },
        timeTo4: {
          type: 'time',
          fullWidth: false,
        },
        timeFrom5: {
          type: 'time',
          fullWidth: false,
        },
        timeTo5: {
          type: 'time',
          fullWidth: false,
        },
        timeFrom6: {
          type: 'time',
          fullWidth: false,
        },
        timeTo6: {
          type: 'time',
          fullWidth: false,
        },
        timeFrom7: {
          type: 'time',
          fullWidth: false,
        },
        timeTo7: {
          type: 'time',
          fullWidth: false,
        },
    },
  },

}

export default schema
