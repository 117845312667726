import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/knowledgePublicationType'
import routes from './routes'
import { useSelector, useDispatch } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'

export const Edit = ({ schema: definitions, ...rest }) => {
    
  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = modifyPropertiesByPage(
          properties,
          resource,
          schema
        )
        return addOpenformProperties(
          profile.isOpenform,
          modifiedProperties,
          resource,
          schema
        )
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      width="100%"
    />
  )
}
