
const schema = {
  endpoint: '/configs',
  resource: {
    definition: 'Config-config:read',
    properties: {

      translations: {
        type: 'translation',
        properties: {

          contactMuseumTitle: {},
          contactMuseumText: {
            type: 'textarea'
          },
          contactMuseumRemarks: {
            type: 'textarea'
          },
          contactOffice: {
            type: 'textarea'
          },
          contactDataInspector: {
            type: 'textarea'
          },
          contactAvaliabilityInspector: {
            type: 'textarea'
          },
          contactHotline: {
            type: 'textarea'
          },
          contactMuseumAddress: {
            type: 'textarea'
          },

        },
      },
      
      cooperationPhoneNumber: {},


      //cooperationPhoneNumber: {},
      //      

    },
  },
}

export default schema
