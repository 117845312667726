//import _block from '../core/_schema/_block'
//import _block from './_articleBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'


const schema = {
  endpoint: '/quiz_questions',
  resource: {
    definition: 'QuizQuestion-quizQuestion:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
            //validate: ['required'],
          },
          text: {
            type: 'tinymce',
          },
          trueAnswerDescription: {
            type: 'tinymce',
          },
          alt: {},
        },
      },

      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/quiz_question_thumbs',
        },
        validate: ['maxSize'],
      },
      file: {
        type: 'file',
        description: 'T_GENERAL_FILE_AUDIO',
        endpoint: fileSchema.endpoint.single,
        validate: ['maxSize', "validExtension('mp3')"],
      },
      youtube: {
        type: 'youtube',
        fullWidth: false,
      },
     
    },
  },
  subresources: {
//    block: {
//      endpoint: '/article_blocks',
//      definition: 'QuizQuestionBlock-articleBlock:read',
//      contextualHint: 'how_to_edit',
//      types: _block(
//        photoSchema.endpoint.single,
//        fileSchema.endpoint.single,
//        '/article_block_sections',
//        '/article_block_thumbs',
//        '/article_block_section_thumbs'
//      ).types,
//    },
  },
  
}

export default schema
