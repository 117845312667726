import React from 'react'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import schema from '_schema/quizAnswer'
import routes from './routes'

import parentRoutes from 'pages/QuizQuestions/routes'


export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <>
        <Form
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={schema.resource}
          iri={iri}
          method="PUT"
          url={iri}
          collectionPath={resource => parentRoutes().show.path.replace(':id', resource.questionUuid) }
          storeCollectionId={schema.endpoint}
          sidebar={true}
          SidebarComponent={Sidebar}
          width={900}
        />
    </>
  )
}
