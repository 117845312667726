import photoSchema from './photo'

const schema = {
  endpoint: '/package_gallery_items',
  resource: {
    definition: 'PackageGalleryItem-packageGalleryItem:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          author: {},
          description: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
//      type: {
//        type: 'media[type]',
//      },
// typ jest 'photo' na sztywno w encji, yt nie chcieli...
      youtube: {
        type: 'media[youtube]',
        description: 'T_GENERAL_YOUTUBE_CODE',
      },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/package_gallery_item_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
