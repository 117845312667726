const schema = {
  endpoint: '/logo_kits',
  resource: {
    definition: 'LogoKit-logoKit:read',
    properties: {
      title: {
        //validate: ['required'],
      },
    },
  },
}

export default schema