import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Box, TextField } from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { Copyright } from 'core/components/Copyright'
import { validators } from 'core/_helpers/validators'
import { translate } from 'core/_helpers/translate'
import { useStyles } from '.'

export const ResetPasswordLink = () => {
  const [state, setState] = useState({
    email: null,
    errorMessage: null,
    isSubmitted: false,
    isInvalid: true,
    isFetching: false,
    isCompleted: false,
  })

  const handleChange = e => {
    const email = getValue(e.target.value)

    const isInvalid =
      !validators.required(email).result || !validators.email(email).result
    setState(state => ({
      ...state,
      email,
      errorMessage: isInvalid
        ? translate('T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL')
        : null,
      isInvalid,
    }))
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/reset_password_link`

  const handleSubmit = e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      isSubmitted: true,
    }))

    if (state.isInvalid) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
      .then(response =>
        response.text().then(text =>
          text
            ? JSON.parse(text)
            : {
                success: false,
                errorMessage: translate('Error'),
              }
        )
      )
      .then(data => {
        if (data.success !== true) {
          setState(state => ({
            ...state,
            errorMessage: translate(data.message),
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }

  const { email, errorMessage, isSubmitted, isFetching, isCompleted } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Logo className={classes.logo} />
        <div className={classes.avatar}>
          {translate('T_GENERAL_FORGOT_PASSWORD_TITLE')}
        </div>
        {isCompleted ? (
          <div className={classes.reset_message}>
            {translate('T_GENERAL_FORGOT_PASSWORD_LINK_SENT')}
          </div>
        ) : (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <div className={classes.form_inner}>
              <TextField
                className={classes.input}
                error={isSubmitted && !!errorMessage}
                helperText={isSubmitted && !!errorMessage ? errorMessage : null}
                value={email || ''}
                onChange={handleChange}
                required={true}
                label={translate('T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL')}
                margin="normal"
                fullWidth={true}
                autoFocus={true}
              />
            </div>
            <Button
              disabled={isFetching}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isFetching ? (
                <img
                  className={classes.spinner}
                  src="/img/react-logo.svg"
                  alt="loading"
                />
              ) : (
                translate('T_GENERAL_FORGOT_PASSWORD_SEND_LINK')
              )}
            </Button>
          </form>
        )}
      </Card>
      <Link to="/login" className={classes.lower_link}>
        {translate('T_GENERAL_BACK_TO_LOGIN')}
      </Link>
      <Box mt={4}>
        <Copyright />
      </Box>
    </div>
  )
}

const getValue = value => (value === '' ? null : value.trim())
