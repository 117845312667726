import React from 'react'
import { Route } from 'react-router-dom'
import { InsertDriveFileOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { Show } from './Show'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/saleOffer'
import { Preview } from '../../core/pages/Preview'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: schema.endpoint,
        exact: true,
        icon: InsertDriveFileOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${schema.endpoint}/new`,
      },
      show: {
          
        // taki sam jak edit
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${schema.endpoint}/:id/edit`,
        exact: true,
//        subtitle: 'T_GENERAL_RECORD_SHOW',
//        type: Route,
//        render: props => (
//          <SchemableComponent path="definitions" component={Show} {...props} />
//        ),
//        exact: true,
//        path: `${schema.endpoint}/:id/show`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${schema.endpoint}/:id/edit`,
        exact: true,
      },
      preview: {
        subtitle: 'T_GENERAL_RECORD_PREVIEW',
        type: Route,
        render: props => (
          <Preview
            path={schema.preview}
            endpoint={schema.endpoint}
            {...props}
          />
        ),
        path: `${schema.endpoint}/:id/preview`,
        exact: true,
        isPreview: true,
        editPath: `${schema.endpoint}/:id/edit`,
      },
    },
    {
      title: 'T_MODULE_SALE_OFFERS',
      resourceAccess: schema.endpoint,
      indexPath: schema.endpoint,
    }
  )

export default routes
