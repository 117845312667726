import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/zonks',
  resource: {
    definition: 'Zonk-zonk:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required'],
          },

          text: {
            type: 'choice',
            choices: {
              1: 'to jest jedynka',
              2: 'to jest dwójka',
            },
            validate: ['required'],
          },
        },
      },

      type: {
        type: 'media[type]',
        fullWidth: false,
      },
      youtube: {
        type: 'media[youtube]',
        description: 'T_GENERAL_YOUTUBE_CODE',
      },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/zonk_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
      },
      //      date: {
      //        type: 'date',
      //        description: 'T_GENERAL_DATE',
      //        validate: ['required'],
      //      },
    },
  },
}

export default schema
