
import {
  //text,
  //textWithQuotation,
  //textWithLink,
  //textWithSections,
  //packageGallery,
} from 'core/_schema/_blocks'

// customowe typy bloczków
import {
    text,
    //packageGallerySimple,
    packageFileSimple,
    //packageFaqSimple,
    //textWithPhotoAndAlt,
    textWithFile,
    //textWithTwoButtonLinks,

    //audio,
    //video,
    //youtube,
    //logoKit,
    //author
} from './_blocks'


const schema = (
  photoEndpoint,
  fileEndpoint,
  sectionEndpoint,
  blockThumbEndpoint,
  sectionThumbEndpoint
) => ({
  types: [
    text(),
    //textWithTwoButtonLinks(),
    //textWithPhotoAndAlt(photoEndpoint, blockThumbEndpoint),
    textWithFile(fileEndpoint),
    
    
    //textWithQuotation(),
    //textWithLink(photoEndpoint, blockThumbEndpoint),
    //textWithSections(photoEndpoint, sectionEndpoint, sectionThumbEndpoint),

    //author(photoEndpoint, blockThumbEndpoint),

    //audio(photoEndpoint, fileEndpoint, blockThumbEndpoint),
    //video(photoEndpoint, fileEndpoint, blockThumbEndpoint),
    //youtube(photoEndpoint, blockThumbEndpoint),

    packageFileSimple(),
    //packageGallerySimple(),
    //packageFaqSimple(),
    //logoKit(),
  ],
})

export default schema

