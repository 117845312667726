import packageGallerySchema from 'core/_schema/packageGallery'

export const packageGallerySimple = () => ({
  name: 'packageGallerySimple',
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY_SIMPLE',
  contextualHint: 'how_to_edit',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        subtitle: {},
//        text: {
//          type: 'tinymce',
//        },
        anchorTitle: {},
      },
    },
    packageGallery: {
      type: 'resource',
      endpoint: `${packageGallerySchema.endpoint}?pagination=false`,
      titleAccessor: 'title',
    },
//    galleryType: {
//      type: 'choice',
//      choices: {
//        slider: 'aaa',
//        tiles: 'bbb',
//      },
//      validate: ['required'],
//    },
  },
})
