import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'

import { translate } from 'core/_helpers/translate'

export const BannerTypeCell = ({
  resource,
  accessor,
}) => {
        
  const availableValuesMap = {
    exhibition: 'T_BANNER_TYPE_RELATED_EXHIBITION',
    event: 'T_BANNER_TYPE_RELATED_EVENT',
    article: 'T_BANNER_TYPE_RELATED_ARTICLE',
    photo: 'T_BANNER_TYPE_PHOTO',
    youtube: 'T_BANNER_TYPE_YOUTUBE',
    video: 'T_BANNER_TYPE_VIDEO',
  }

  const mappedValue = prop(resource, accessor);
    
  const resultValue = prop(availableValuesMap, mappedValue);

  return <div>{ translate(resultValue) }</div>
}

BannerTypeCell.propTypes = {
    
  resource: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  
}
