import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { DateCell } from 'core/components/table/cells/DateCell'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/knowledgeTarget'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterable: false,
        sortable: false,
        width: '58%',
      },
//      {
//        accessor: 'date',
//        header: translate('T_GENERAL_DATE'),
//        filterable: false,
//        sortable: true,
//        Cell: DateCell,
//        width: '15%',
//      },
      {
        ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, false ),
        width: '15%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_KNOWLEDGE_TARGETS_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_KNOWLEDGE_TARGETS')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
