import packageFileSchema from 'core/_schema/packageFile'

export const packageFileSimple = () => ({
  name: 'packageFileSimple',
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_FILE_SIMPLE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        subtitle: {},
        anchorTitle: {},
      },
    },
    packageFile: {
      type: 'resource',
      endpoint: `${packageFileSchema.endpoint}?pagination=false`,
      titleAccessor: 'title',
    },
  },
})
