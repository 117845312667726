export const text = () => ({
  name: 'text',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_1_2_COLUMNS',
  properties: {
    isTwoColumns:{
      type: 'boolean',
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'textarea',
        },
        //subtitle: {},
        text: {
          type: 'tinymce',
        },
        anchorTitle: {},
      },
    },
  },
})
