import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'core/components/Loader'
import { Avatar } from '.'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { isAuthor as isLoggedAuthor } from 'core/pages/Messenger/_helpers/isAuthor'
import { constants } from 'core/pages/Messenger/_state'
import config from 'core/pages/Messenger/_helpers/config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '20px 10px',
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
  isAuthor: {
    cursor: 'pointer',
  },
  isRecipient: {
    backgroundColor: theme.elements.messenger.recipient.background,
    justifyContent: 'flex-end',
  },
  timestamp: {
    color: theme.elements.messenger.timestamp.color,
    marginBottom: 5,
  },
  updatedAt: {
    fontStyle: 'italic',
    fontSize: '.9em',
    marginTop: 3,
  },
  innerContainer: {
    flexGrow: 2,
  },
  recipientContainer: {
    textAlign: 'right',
  },
  editable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    '&> *': {
      marginBottom: 10,
    },
  },
  textarea: {
    '&> *': {
      padding: 8,
    },
  },
}))

export const PersistedMessage = ({
  message,
  dispatch = null,
  classes = {},
}) => {
  const profile = useSelector(state => state.profile)
  const isAuthor = isLoggedAuthor(message.author, profile)

  const [state, setState] = useState({
    staticText: message.text,
    editableText: message.text,
    updatedAt: message.updatedAt,
    isEditMode: false,
    isFetching: false,
  })

  const textFieldRef = useRef()
  const buttonRef = useRef()

  const handleEditMode = e => {
    const target = e.target.nodeName === 'SPAN' ? e.target.parentNode : e.target

    if ([textFieldRef.current, buttonRef.current].includes(target)) {
      return
    }

    setState(state => ({
      ...state,
      isEditMode: !state.isEditMode,
    }))
  }

  const handleEditableText = e => {
    const value = e.target.value

    setState(state => ({
      ...state,
      editableText: value,
    }))
  }

  const handleSubmit = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      message['@id'],
      'PATCH',
      {
        body: JSON.stringify({
          text: state.editableText,
        }),
      },
      response => {
        dispatch &&
          dispatch({
            type: constants.UPDATE_ITEM,
            payload: { value: response },
          })

        setState({
          staticText: response.text,
          editableText: response.text,
          updatedAt: response.updatedAt,
          isEditMode: false,
          isFetching: false,
        })

        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
        }))

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const additional = isAuthor
    ? {
        onClick: handleEditMode,
      }
    : {}

  const defaultClasses = useStyles()

  return (
    <div
      className={clsx(
        defaultClasses.root,
        classes.root,
        isAuthor ? defaultClasses.isAuthor : defaultClasses.isRecipient,
        isAuthor ? classes.isAuthor : classes.isRecipient
      )}
      {...additional}
    >
      {isAuthor && <Avatar name={message.author.fullName} />}
      <div
        className={clsx(
          defaultClasses.innerContainer,
          classes.innerContainer,
          !isAuthor && defaultClasses.recipientContainer
        )}
      >
        <div className={clsx(defaultClasses.timestamp, classes.timestamp)}>
          {moment(message.createdAt).format('HH:mm DD.MM.YYYY')}
          {message.createdAt !== state.updatedAt && (
            <div className={clsx(defaultClasses.updatedAt, classes.updatedAt)}>
              {translate('T_MODULE_MESSENGER_UPDATED_AT')}:{' '}
              {moment(state.updatedAt).format('HH:mm DD.MM.YYYY')}
            </div>
          )}
        </div>
        {isAuthor && state.isEditMode ? (
          <div className={clsx(defaultClasses.editable, classes.editable)}>
            {state.isFetching && <Loader />}
            <TextField
              rows={config.PERSISTED_MESSAGE_TEXTAREA_ROWS}
              rowsMax={config.PERSISTED_MESSAGE_TEXTAREA_ROWS}
              multiline={true}
              variant="outlined"
              onChange={handleEditableText}
              value={state.editableText}
              inputRef={textFieldRef}
              inputProps={{ maxLength: config.MESSAGE_TEXTAREA_MAX_LENGTH }}
              classes={{ root: defaultClasses.textarea }}
            />
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSubmit}
                ref={buttonRef}
                disabled={!state.editableText || state.isFetching}
              >
                {translate('T_GENERAL_SAVE')}
              </Button>
            </div>
          </div>
        ) : (
          state.staticText
        )}
      </div>
      {!isAuthor && <Avatar name={message.author.fullName} />}
    </div>
  )
}

PersistedMessage.propTypes = {
  message: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    author: PropTypes.shape({
      [process.env.REACT_APP_RESOURCE_ID]: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }).isRequired,
    text: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string,
    isAuthor: PropTypes.string,
    isRecipient: PropTypes.string,
    timestamp: PropTypes.string,
    updatedAt: PropTypes.string,
    innerContainer: PropTypes.string,
    editable: PropTypes.string,
  }),
}
