export const textWithLink = (photoEndpoint, thumbEndpoint) => ({
  name: 'textWithLink',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        subtitle: {},
        text: {
          type: 'tinymce',
        },
        buttonTitle: {},
        anchorTitle: {},
      },
    },
    buttonLink: {},
    targetBlank:{
      type: 'boolean',
      label: 'Otwórz w nowym oknie',
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
  },
})
