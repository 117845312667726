import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  Switch,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginTop: 20,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 10,
    verticalAlign: 'middle',
  },
  hint: {
    color: theme.palette.text.secondary,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const BooleanType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  validators,
  setValue,
  setError,
  classes = {},
}) => {
    
  label = !label ? name : label
    
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.checked
    setValue(name, value)
    validateField(name, value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(!!initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (value === null && [undefined, null].includes(initialValue)) {
      setValue(name, false)
    }
  }, [setValue, name, initialValue])

  const defaultClasses = useStyles()

  return (
    <FormControl
      className={clsx(defaultClasses.root, classes.root)}
      disabled={disabled}
    >
      <InputLabel
        htmlFor={id}
        className={clsx(defaultClasses.label, classes.label)}
      >
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Switch
        id={id}
        name={name}
        checked={value || false}
        onChange={handleChange}
        color="primary"
      />
      {compare && (
        <div
          className={clsx(
            defaultClasses.compare,
            value !== compareValue && defaultClasses.compareNeq
          )}
        >
          {translate(
            compareValue
              ? 'T_FORM_FIELD_BOOLEAN_ON'
              : 'T_FORM_FIELD_BOOLEAN_OFF'
          )}
        </div>
      )}
      <FormHelperText className={clsx(defaultClasses.hint, classes.hint)}>
        {hint}
      </FormHelperText>
    </FormControl>
  )
}

BooleanType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.bool,
  value: PropTypes.bool,
  compareValue: PropTypes.bool,
  compare: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
  }),
}
