import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ChoiceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  choices,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.value
    setValue(name, !value || value === 'null' ? null : value)
    validateField(!value || value === 'null' ? null : value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
      disabled={disabled || !Object.keys(choices).length}
    >
      <InputLabel htmlFor={id}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label) +
            (validators && validators.includes('required') ? ' *' : '')}
        </span>
      </InputLabel>
      <Select
        id={id}
        name={name}
        onChange={handleChange}
        value={
          Object.keys(choices).length
            ? value ||
              (!validators || !validators.includes('required') ? 'null' : '')
            : ''
        }
      >
        {Object.keys(choices).length &&
          (!validators || !validators.includes('required')) && (
            <MenuItem value="null">{translate('T_GENERAL_EMPTY')}</MenuItem>
          )}
        {Object.keys(choices).map(key => (
          <MenuItem value={key} key={key}>
            {translate(choices[key])}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {Object.keys(choices).length &&
            compareValue &&
            translate(choices[compareValue])}
        </div>
      )}
    </FormControl>
  )
}

ChoiceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  choices: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
