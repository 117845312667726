import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import Moment from 'moment';

const useStyles = makeStyles({
  dates: {
    // alignItems: 'center',
  },
})

export const DatesCell = ({
  resource,
  accessor,
  subAccessor,
}) => {
  const history = useHistory()

  const classes = useStyles()

  return (
    <div className={classes.dates}>
      {resource[accessor].map(item => {
          return (
             <span>
               { item }<br/>
             </span>
          )
        }
      )}
    </div>
  )
}