import React, { useMemo } from 'react'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import schema from '_schema/educationActivity'
import routes from './routes'
import parentRoutes from 'pages/EducationAgeGroups/routes'
import { BlockCollection } from 'core/components/block'

import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'

import packageFileSchema from 'core/_schema/packageFile'
import packageGallerySchema from 'core/_schema/packageGallery'
import packageFaqSchema from 'core/_schema/packageFaq'
import packageFormSchema from 'core/_schema/packageForm'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const packageResourcesMapping = useMemo(
    () => ({
      packageFile: packageFileSchema.endpoint,
      packageGallery: packageGallerySchema.endpoint,
      packageFaq: packageFaqSchema.endpoint,
      packageForm: packageFormSchema.endpoint,
    }),
    []
  )

  const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)

  const blockTypes = useMemo(
    () =>
      addBlockPropertyResources(
        schema.subresources.block.types,
        packageResources
      ),
    [packageResources]
  )

  return (
    <>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={resource =>
          parentRoutes().show.path.replace(
            ':id',
            resource.educationAgeGroupUuid
          )
        }
        storeCollectionId={schema.endpoint}
        sidebar={true}
        SidebarComponent={Sidebar}
        width={900}
      >
        <BlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={blockTypes}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          key={iri}
        />
      </Form>
    </>
  )
}
