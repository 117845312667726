import menuSchema from '_schema/menu'

export const menu = () => ({
  name: 'menu',
  label: 'T_GENERAL_BLOCK_TYPE_MENU',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        anchorTitle: {},
      },
    },
    menuId: {
      label: 'wybierz menu',
      type: 'resourceChoice',
      endpoint: `${menuSchema.endpoint}?pagination=false`,
      valueAccessor: 'id',
      labelAccessor: 'translations.pl.title',
    },
  },
})
