import React, { useMemo } from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { TopBar, SideBar, PreviewBar } from '.'
import { generateBreadcrumbs } from 'core/_helpers/generateBreadcrumbs'
import { getCurrentRoute } from 'core/_helpers/getCurrentRoute'

export const Bars = ({ location, menu, routes, loading = false }) => {
  const isSidebarOpen = useSelector(state => state.common.isSidebarOpen)
  const customBreadcrumbs = useSelector(state => state.common.customBreadcrumbs)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { pathname, search } = location

  const breadcrumbs = useMemo(() => {
    const { pid } = queryString.parse(search)
    const currentPath = `${pathname}${pid ? `?pid=${pid}` : ''}`
    return {
      paths:
        customBreadcrumbs?.[currentPath] ||
        generateBreadcrumbs(pathname, routes),
      custom: !!customBreadcrumbs?.[currentPath],
    }
  }, [customBreadcrumbs, pathname, search, routes])

  const { route, match } = getCurrentRoute(pathname, routes) || {
    route: null,
    match: null,
  }

  return route?.isPreview ? (
    <PreviewBar
      breadcrumbs={breadcrumbs}
      editPath={route?.editPath.replace(':id', match.params.id)}
      loading={loading}
    />
  ) : (
    <>
      <TopBar
        breadcrumbs={breadcrumbs}
        isMobile={isMobile}
        isSidebarOpen={!isMobile && isSidebarOpen}
        loading={loading}
      />
      <SideBar
        menu={menu}
        isSidebarOpen={!isMobile && isSidebarOpen}
        loading={loading}
      />
    </>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
})

Bars.propTypes = {
  location: PropTypes.object.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
      from: PropTypes.string,
      exact: PropTypes.bool,
    })
  ).isRequired,
  loading: PropTypes.bool,
}
