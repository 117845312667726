//import _block from '../core/_schema/_block'
import _block from './_publicTenderBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'


const schema = {
  endpoint: '/public_tenders',
  preview: '/preview/public_tenders/:id',
  resource: {
    definition: 'PublicTender-publicTender:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          //alt: {},
        },
      },
 
      isArchive:{
        type: 'boolean',
      },    
      date: {
        type: 'date',
        description: 'T_GENERAL_DATE',
        validate: ['required'],
      },
      plannedPublishDateTime: {
        type: 'date',
        fullWidth: false,
        description: 'T_GENERAL_PLANNED_PUBLISH_DATE_TIME',
        validate: ['required'],
      },
//      photo: {
//        type: 'image',
//        description: 'T_GENERAL_PUBLIC_TENDER_IMAGE',
//        endpoint: photoSchema.endpoint.single,
//        //endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/publicTender_thumbs',
//        },
//        validate: ['maxSize'],
//      },

    },
  },
  subresources: {
    block: {
      endpoint: '/public_tender_blocks',
      definition: 'PublicTenderBlock-publicTenderBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/public_tender_block_sections',
        '/public_tender_block_thumbs',
        '/public_tender_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
