import { StringType } from './StringType'
import { IntegerType } from './IntegerType'
import { NumberType } from './NumberType'
import { TinymceType } from './TinymceType'
import { PasswordRepeatType } from './PasswordRepeatType'
import { BooleanType } from './BooleanType'
import { ResourceType } from './ResourceType'
import { MultipleResourceType } from './MultipleResourceType'
import { DateType } from './DateType'
import { TimeType } from './TimeType'
import { DateTimeType } from './DateTimeType'
import { MapType } from './MapType'
import { TranslationType } from './translation/TranslationType'
import { FileType } from './file/FileType'
import { ImageType } from './ImageType'
import { YoutubeType } from './YoutubeType'
import { ChoiceType } from './ChoiceType'
import { ResourceChoiceType } from './ResourceChoiceType'
import { MediaType, MediaImageType, MediaYoutubeType } from './media'
import { CollectionType } from './CollectionType'
import { Title } from './Title'

import {
    MediaBannerType,
    MediaBannerVideoType,
    MediaBannerImageType,
    MediaBannerYoutubeType,
    MediaBannerResourceType
} from './mediaBanner'

export const fields = {
  title: Title,
  string: StringType,
  integer: IntegerType,
  number: NumberType,
  textarea: StringType,
  tinymce: TinymceType,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
  boolean: BooleanType,
  resource: ResourceType,
  multipleResource: MultipleResourceType,
  date: DateType,
  time: TimeType,
  datetime: DateTimeType,
  map: MapType,
  translation: TranslationType,
  file: FileType,
  image: ImageType,
  youtube: YoutubeType,
  choice: ChoiceType,
  resourceChoice: ResourceChoiceType,
  'media[type]': MediaType,
  'media[image]': MediaImageType,
  'media[youtube]': MediaYoutubeType,
  'mediaBanner[type]': MediaBannerType,
  'mediaBanner[video]': MediaBannerVideoType,
  'mediaBanner[image]': MediaBannerImageType,
  'mediaBanner[youtube]': MediaBannerYoutubeType,
  'mediaBanner[resource]': MediaBannerResourceType,
  collection: CollectionType,
}
