//import _block from '../core/_schema/_block'
//import _block from './_sponsorFirmBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import calendarSchema from './calendar'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/sponsor_firms',
  resource: {
    definition: 'SponsorFirm-sponsorFirm:read',
    properties: {
      firmName: {
        validate: ['required'],
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/sponsor_firm_thumbs',
        },
        validate: ['maxSize'],
      },
    },
  },
  subresources: {
//    block: {
//      endpoint: '/calendar_blocks',
//      definition: 'SponsorFirmBlock-calendarBlock:read',
//      contextualHint: 'how_to_edit',
//      types: _block(
//        photoSchema.endpoint.single,
//        fileSchema.endpoint.single,
//        '/calendar_block_sections',
//        '/calendar_block_thumbs',
//        '/calendar_block_section_thumbs'
//      ).types,
//    },
  },
  
}

export default schema
