import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { constants } from 'core/pages/Messenger/_state'
import config from 'core/pages/Messenger/_helpers/config'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '&> *': {
      marginBottom: 10,
    },
  },
  loader: {
    marginBottom: 20,
  },
})

export const NewMessage = ({
  endpoint,
  recipient,
  dispatch = null,
  disabled = false,
  classes = {},
}) => {
  const [state, setState] = useState({
    text: '',
    isFetching: false,
  })

  const handleChange = e => {
    const text = e.target.value
    setState(state => ({
      ...state,
      text,
    }))
  }

  const handleSubmit = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          recipient,
          text: state.text,
        }),
      },
      response => {
        dispatch &&
          dispatch({ type: constants.ADD_ITEM, payload: { value: response } })

        setState({
          text: '',
          isFetching: false,
        })

        notification('success', 'T_MODULE_MESSENGER_SENT', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
        }))

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      {state.isFetching && (
        <Loader className={clsx(defaultClasses.loader, classes.loader)} />
      )}
      <TextField
        label={translate('T_MODULE_MESSENGER_MESSAGE')}
        rows={config.NEW_MESSAGE_TEXTAREA_ROWS}
        rowsMax={config.NEW_MESSAGE_TEXTAREA_ROWS}
        multiline={true}
        variant="outlined"
        onChange={handleChange}
        value={state.text}
        disabled={disabled || state.isFetching}
      />
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit}
          disabled={disabled || !state.text || state.isFetching}
        >
          {translate('T_GENERAL_SEND')}
        </Button>
      </div>
    </div>
  )
}

NewMessage.propTypes = {
  endpoint: PropTypes.string.isRequired,
  recipient: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    loader: PropTypes.string,
  }),
}
