import React from 'react'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import schema from 'core/_schema/packageForm'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().show.path.replace(':id', match.params.id)}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      SidebarComponent={Sidebar}
      width={600}
    />
  )
}
