import React from 'react'
import PropTypes from 'prop-types'
import { StringType } from './StringType'

export const IntegerType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => (
  <StringType
    name={name}
    type="integer"
    label={label}
    hint={hint}
    initialValue={initialValue}
    value={value}
    compareValue={compareValue}
    compare={compare}
    error={error}
    renderError={renderError}
    disabled={disabled}
    validators={validators}
    setValue={setValue}
    setError={setError}
    fullWidth={fullWidth}
  />
)

IntegerType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.number,
  value: PropTypes.number,
  compareValue: PropTypes.number,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
