import React from 'react'
import { Form } from 'core/pages'
//import { Sidebar } from './sidebar'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import schema from '_schema/eventDate'
import parentRoutes from 'pages/Events/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (    
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={resource =>
        parentRoutes().show.path.replace(
          ':id',
          resource.event[process.env.REACT_APP_RESOURCE_ID]
        )
      }
      storeCollectionId={iri}
      fieldsFullWidth={false}
      width={700}
      
    />
  )
}



