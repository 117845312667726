import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'

import { translate } from 'core/_helpers/translate'

export const StatusTextCell = ({
  resource,
  accessor,
}) => {

  const mappedValue = prop(resource, accessor);

  return <div>{ mappedValue }</div>
}

StatusTextCell.propTypes = {
    
  resource: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  
}
