import { authHeader } from './authHeader'
import { notification } from 'core/_helpers/notification'

export const fetchFrontPreview = (url, request, setPreview) =>
  fetch(url, {
    method: 'GET',
    headers: {
      ...authHeader(),
    },
    ...request,
  })
    .then(
      response => {
        if (!response.ok) {
          notification(
            'error',
            'T_FRONT_PREVIEW_ERROR_TEXT',
            'T_FRONT_PREVIEW_ERROR_TITLE'
          )

          return ''
        }

        return response.text()
      },
      () => {}
    )
    .then(html => setPreview(html))
