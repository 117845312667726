import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { List, ListItem } from '@material-ui/core'
import { Status } from 'core/components/resource/Status'
import { Delete } from 'core/components/resource/Delete'
import parentRoutes from 'pages/LogoKits/routes'
import { useStyles } from 'core/components/resource/sidebar/Sidebar.styles'

export const Sidebar = ({
  resource,
  titleAccessor = `translations.${process.env.REACT_APP_LOCALE}.title`,
  statAccessor = 'stat',
  disabled = false,
}) => {
  const history = useHistory()
  const defaultClasses = useStyles()
  const onDeleteSuccess = () => {
    history.push(
      parentRoutes().show.path.replace(
        ':id',
        resource.logoKit[process.env.REACT_APP_RESOURCE_ID]
      )
    )
  }

  return (
    <List className={defaultClasses.list}>
      <ListItem className={defaultClasses.item}>
        <Status
          resource={resource}
          accessor={statAccessor}
          disabled={disabled}
          isSwitch={false}
        />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <Delete
          resource={resource}
          accessor={titleAccessor}
          disabled={disabled}
          onSuccess={onDeleteSuccess}
        />
      </ListItem>
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    parent: PropTypes.shape({
      [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    }),
  }).isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  disabled: PropTypes.bool,
}
