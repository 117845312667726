const schema = {
  endpoint: '/package_forms',
  resource: {
    definition: 'PackageForm-packageForm:read',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
}

export default schema
