const schema = {
  endpoint: '/package_faqs',
  resource: {
    definition: 'PackageFaq-packageFaq:read',
    properties: {
      title: {
        //validate: ['required'],
      },
    },
  },
}

export default schema
