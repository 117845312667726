import adminGroupSchema from './adminGroup'

const schema = {
  endpoint: '/admins',
  resource: {
    definition: 'Admin:e1a81ee849bb762945e95c3a98bdb76a-admin:write',
    properties: {
      fullName: {
        validate: ['required', 'length(3)'],
      },
      email: {
        validate: ['required', 'email'],
      },
      password: {
        type: 'passwordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['required', 'password'],
      },
      group: {
        type: 'resource',
        endpoint: `${adminGroupSchema.endpoint}?order[createdAt]=desc&pagination=false`,
        titleAccessor: 'title',
      },
    },
  },
}

export default schema
