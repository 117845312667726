//import _block from '../core/_schema/_block'
//import _block from './_quizBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import quizSchema from './quiz'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/quizzes',
  resource: {
    definition: 'Quiz-quiz:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          //text: {
          //  type: 'tinymce',
          //},
          alt: {},
          photoTitle: {},
        },
      },

      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/quiz_thumbs',
        },
        validate: ['maxSize'],
      },  
    },
  },
  subresources: {
      
// Bloki są odłączone bo nie są potrzebne
//    block: {
//      endpoint: '/quiz_blocks',
//      definition: 'QuizBlock-quizBlock:read',
//      contextualHint: 'how_to_edit',
//      types: _block(
//        photoSchema.endpoint.single,
//        fileSchema.endpoint.single,
//        '/quiz_block_sections',
//        '/quiz_block_thumbs',
//        '/quiz_block_section_thumbs'
//      ).types,
//    },
    
    
  },
  
}

export default schema
