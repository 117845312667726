export const audio = (photoEndpoint, fileEndpoint, thumbEndpoint) => ({
  name: 'audio',
  label: 'T_GENERAL_BLOCK_TYPE_AUDIO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        subtitle: {},
        photoAlt: {
            type: 'string'
        },
        anchorTitle: {},
      },
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
    file: {
      type: 'file',
      fullWidth: false,
      label: 'T_GENERAL_AUDIO_FILE',
      endpoint: fileEndpoint,
    },
  },
})
