
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'


const schema = {
  endpoint: '/quiz_answers',
  resource: {
    definition: 'QuizAnswer-quizAnswer:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
            //validate: ['required'],
          },
//          text: {
//            type: 'tinymce',
//          },
//          alt: {},
        },
      },
      isCorrect: {
        type: 'boolean',
        label: 'Ta odpowiedź jest poprawna',
      },
      
//      photo: {
//        type: 'image',
//        description: 'T_GENERAL_ARTICLE_IMAGE',
//        endpoint: photoSchema.endpoint.single,
//        //endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/article_thumbs',
//        },
//        validate: ['maxSize'],
//      },

     
    },
  },
  subresources: {
//    block: {
//      endpoint: '/article_blocks',
//      definition: 'QuizAnswerBlock-articleBlock:read',
//      contextualHint: 'how_to_edit',
//      types: _block(
//        photoSchema.endpoint.single,
//        fileSchema.endpoint.single,
//        '/article_block_sections',
//        '/article_block_thumbs',
//        '/article_block_section_thumbs'
//      ).types,
//    },
  },
  
}

export default schema
