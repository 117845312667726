import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import randomHash from 'random-hash';
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError';
import { notification } from 'core/_helpers/notification';
import { validate } from 'core/_helpers/validate';
import { translate } from 'core/_helpers/translate';
import { prop } from 'core/_helpers/prop';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}));

export const ResourceChoiceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  valueAccessor,
  labelAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash());
  const [choices, setChoices] = useState([]);

  const handleChange = (e, option) => {
    setValue(name, option ? option[valueAccessor] : null);
    validateField(option ? option[valueAccessor] : null);
  };

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false);
        return;
      }

      const valid = validate(validators, value);
      setError(name, !valid.result && valid.message);
    },
    [validators, setError, name]
  );

  useEffect(() => {
    validateField(initialValue);
  }, [validateField, initialValue]);

  useEffect(() => {
    if (!endpoint) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
          
        var values = response['hydra:member'].map(option => ({
          [valueAccessor]: prop(option, valueAccessor) || '',
          [labelAccessor]: prop(option, labelAccessor) || '', 
        }))          
                  
        setChoices(values);
      },
      error => {
        if (error.response.title === 'AbortError') {
          return;
        }

        notification('error', error.response.detail, error.response.title);
      }
    );

    return () => controller.abort();
  }, [endpoint, valueAccessor, labelAccessor]);

  const classes = useStyles();

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
    >
      <Autocomplete
        id={id}
        name={name}
        options={choices}
        getOptionLabel={option => option[labelAccessor]}
        onChange={handleChange}
        value={choices.find(option => option[valueAccessor] === value) || null}
        renderInput={params => (
          <TextField
            {...params}
            label={
              translate(label ? (label.text || label) : name) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            variant="outlined"
          />
        )}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        classes={{ root: classes.autocomplete }}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {choices.find(option => option[valueAccessor] === compareValue)?.[labelAccessor]}
        </div>
      )}
    </FormControl>
  );
};

ResourceChoiceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  endpoint: PropTypes.string,
  valueAccessor: PropTypes.string.isRequired,
  labelAccessor: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};