const schema = {
  endpoint: '/package_form_items',
  resource: {
    definition: 'PackageFormItem-packageFormItem:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
        },
      },
      type: {
        type: 'choice',
        choices: {
          text: 'T_MODULE_PACKAGE_FORM_ITEM_TYPE_TEXT',
          textarea: 'T_MODULE_PACKAGE_FORM_ITEM_TYPE_TEXTAREA',
          radio: 'T_MODULE_PACKAGE_FORM_ITEM_TYPE_RADIO',
          checkbox: 'T_MODULE_PACKAGE_FORM_ITEM_TYPE_CHECKBOX',
        },
        validate: ['required'],
      },
      isRequired: {},
    },
  },
  subresources: {
    element: {
      endpoint: '/package_form_item_variants',
      definition: 'PackageFormItemVariant-packageFormItemVariant:read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
      },
    },
  },
}

export default schema
