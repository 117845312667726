import _block from '../core/_schema/_block'
import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/coordinators',
  resource: {
    definition: 'Coordinator-coordinator:read',
    properties: {
      firstName: {
        validate: ['required'],
      },
      lastName: {
        validate: ['required'],
      },
      email: {
        validate: ['required'],
      },
      phone: {
        validate: ['required'],
      },
      hours: {
        validate: ['required'],
        type: 'textarea',
      },
        
      isVisitCoordinator:{
        type: 'boolean',
      },
      isGuideServiceCoordinator:{
        type: 'boolean',
      },
      isShiftCoordinator:{
        type: 'boolean',
      },    
      
    },
  },
  
}

export default schema
