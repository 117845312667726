import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { DateTimeCell } from 'core/components/table/cells/DateTimeCell'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/cooperationMessage'
import routes from './routes'

import { StatusCell } from 'core/components/table/cells/StatusCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        accessor: 'createdAt',
        header: translate('T_GENERAL_DATE_TIME'),
        filterable: false,
        sortable: true,
        Cell: DateTimeCell,
        width: '12%',
      },
      {
        header: translate('T_GENERAL_MESSAGE'),
        accessor: `text`,
        filterable: false,
        sortable: false,
      },
      {
        header: translate('T_GENERAL_IS_READED'),
        accessor: `isReaded`,
        filterable: true,
        sortable: false,
        width: '7%',
        Cell: StatusCell,
      },
      {
        ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, false ),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_COOPERATION_MESSAGES_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_COOPERATION_MESSAGES')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
