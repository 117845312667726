import packageFileSchema from 'core/_schema/packageFile'

export const packageFile = () => ({
  name: 'packageFile',
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_FILE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {},
        text: {
          type: 'tinymce',
        },
        anchorTitle: {},
      },
    },
    packageFile: {
      type: 'resource',
      endpoint: `${packageFileSchema.endpoint}?pagination=false`,
      titleAccessor: 'title',
    },
  },
})
