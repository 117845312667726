//import _block from '../core/_schema/_block'
import _block from './_knowledgeBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import knowledgeSchema from './knowledge'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/knowledges',
  preview: '/preview/knowledges/:id',
  resource: {
    definition: 'Knowledge-knowledge:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          subtitle: {
            validate: [],
            type: 'textarea',
          },
          shortDescription: {
            validate: [],
          },
          text: {
            type: 'textarea',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          alt: {},
        },
      },
      date: {
        type: 'date',
        description: 'T_GENERAL_DATE',
        validate: ['required'],
      },
      quiz: {
        description: 'T_MODULE_QUIZ',
        //validate: ['required'],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/quizzes?order[ord]=desc&pagination=false`,
      },
      
      isPromoted:{
        type: 'boolean',
      },
      isAudioDescription:{
        type: 'boolean',
      },
      isSignLanguage:{
        type: 'boolean',
      },
      isTranscription:{
        type: 'boolean',
      },
      knowledgePublicationType: {
        description: 'T_MODULE_KNOWLEDGE_PUBLICATION_TYPE',
        validate: ['required'],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledge_publication_types?order[ord]=desc&pagination=false`,
      },
      knowledgePublicationTypesAdditional: {
        description: 'T_MODULE_KNOWLEDGE_PUBLICATION_TYPES_ADDITIONAL',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledge_publication_types?order[ord]=desc&pagination=false`,
      },
      
      knowledgeTargets: {
        description: 'T_MODULE_KNOWLEDGE_TARGETS',
        validate: ['required'],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledge_targets?order[ord]=desc&pagination=false`,
      },
      knowledgeGenres: {
        description: 'T_MODULE_KNOWLEDGE_GENRES',
        validate: ['required'],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledge_genres?order[ord]=desc&pagination=false`,
      },
      knowledgeEpochs: {
        description: 'T_MODULE_KNOWLEDGE_EPOCHS',
        validate: ['required'],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledge_epochs?order[ord]=desc&pagination=false`,
      },
      
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/knowledge_thumbs',
        },
        validate: ['maxSize'],
      },


      relatedKnowledges: {
        description: 'T_GENERAL_RELATED_KNOWLEDGES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledges?order[createdAt]=desc&pagination=false`,
      },
      relatedArticles: {
        description: 'T_GENERAL_RELATED_ARTICLES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/articles?order[createdAt]=desc&pagination=false`,
      },      
      relatedEvents: {
        description: 'T_GENERAL_RELATED_EVENTS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/events?order[ord]=desc&pagination=false`,
      },
      relatedExhibitions: {
        description: 'T_GENERAL_RELATED_EXHIBITIONS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/exhibitions?order[ord]=desc&pagination=false`,
      },
 
      
    },
  },
  subresources: {
    block: {
      endpoint: '/knowledge_blocks',
      definition: 'KnowledgeBlock-knowledgeBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/knowledge_block_sections',
        '/knowledge_block_thumbs',
        '/knowledge_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
